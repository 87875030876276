import { HFlow, Icon, Text, Tooltip } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors'
import React, { ReactNode } from 'react'
import { CypressProps } from 'types/cypress'

interface PreNatalCardItemsProps<T> extends CypressProps {
  label: string
  content: ReactNode
  value: T
  isRegistradoAgora: boolean
}

export function PreNatalCardItems<T>({
  label,
  value,
  content,
  isRegistradoAgora,
  dataTestId,
}: PreNatalCardItemsProps<T>): JSX.Element {
  return (
    <HFlow alignItems='center' hSpacing={0.3} data-testid={dataTestId}>
      <Text>
        <strong>{`${label}: `}</strong>
        {value ? content : 'Não informado'}
      </Text>
      {isRegistradoAgora && (
        <Tooltip text='Registrado agora'>
          <Icon color={blue.c40} size={1} icon='clockOutline' />
        </Tooltip>
      )}
    </HFlow>
  )
}
