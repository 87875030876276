import { Alert, Cell, FormControl, Grid, Paper, Text, Theme, useStyles, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { ErrorField } from 'components/form'
import { RadioFieldContainer } from 'components/form/field/RadioFieldContainer'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { useIsCidadaoPresenteOutroAtendimentoNaListaQuery } from 'graphql/hooks.generated'
import { CondutaEnum, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import React, { useEffect } from 'react'
import Permissions from 'types/Permissions'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath } from 'util/metaPath'
import { podeFinalizarAtendimentoObservacao } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'
import { meta as metaSoap } from 'view/atendimentos/atendimento-individual/model'
import { EncaminhamentoForm } from 'view/atendimentos/detail/soap/finalizacao/components/EncaminhamentoForm'
import { EncaminhamentoInternoFormModel } from 'view/atendimentos/model'
import { AgendamentoDia } from 'view/atendimentos/types/AgendamentoDia'

import { CondutaPanel } from './CondutaPanel'

export enum ManterCidadaoLista {
  SIM = 'SIM',
  NAO = 'NAO',
}

export interface DesfechoAtendimentoFormModel {
  manterCidadaoLista: ManterCidadaoLista
  atendimento?: EncaminhamentoInternoFormModel
}

export interface DesfechoAtendimentoPanelProps {
  name: MetaPath<DesfechoAtendimentoFormModel>
  condutasName?: MetaPath<CondutaEnum[]>
  agendamentosDia?: AgendamentoDia[]
  labelManterCidadaoLista?: string
  labelPanel?: string
  isAtendimentoObservacao?: boolean
  isObservacaoAndResponsavel?: boolean
  isObservacaoAndAuxiliar?: boolean
  hasNotificacaoCasoSuspeito?: boolean
  hasEncaminhamentoExterno?: boolean
  isRegistroTardio?: boolean
  cidadaoId: ID
  atendimentoId: ID
}

DesfechoAtendimentoPanel.defaultProps = {
  labelPanel: 'Desfecho do atendimento',
  labelManterCidadaoLista: 'Manter cidadão na lista de atendimentos',
} as DesfechoAtendimentoPanelProps

const TOOLTIP_START_OBSERVACAO = 'O cidadão será encaminhado para observação.'
const TOOLTIP_NAO_RESPONSAVEL_PELA_OBSERVACAO_UBS = 'Somente médicos e enfermeiros podem liberar o cidadão.'
const TOOLTIP_NAO_RESPONSAVEL_PELA_OBSERVACAO_UPA =
  'Somente o profissional responsável e outros médicos podem liberar o cidadão.'
const TOOLTIP_NAO_MANTER_NA_LISTA =
  'Não é possível manter o cidadão na lista de atendimento, pois ele já está presente na lista de atendimento.'

export function DesfechoAtendimentoPanel(props: DesfechoAtendimentoPanelProps) {
  const {
    name,
    condutasName,
    agendamentosDia,
    labelManterCidadaoLista,
    labelPanel,
    hasNotificacaoCasoSuspeito,
    hasEncaminhamentoExterno,
    cidadaoId,
    atendimentoId,
    isRegistroTardio = false,
    isAtendimentoObservacao = false,
    isObservacaoAndResponsavel = false,
    isObservacaoAndAuxiliar = false,
  } = props

  const { classes } = useStyles(createStyles)
  const { acesso, tipoEstabelecimento } = useAcessoLotacaoOrEstagio()

  const {
    input: { value: manterCidadaoLista, onChange },
  } = usePecField({ name: name.manterCidadaoLista, subscription: { value: true } })

  const {
    input: { value: isStartObservacao },
  } = usePecField({ name: metaSoap.plano?.startObservacao, subscription: { value: true } })

  const isStartOuAtendimentoObservacao = isStartObservacao || isAtendimentoObservacao
  const isUpa = tipoEstabelecimento === TipoEstabelecimentoEnum.UPA

  const {
    data: { presenteOutroAtendimentoNaLista },
    loading,
  } = useIsCidadaoPresenteOutroAtendimentoNaListaQuery({
    variables: {
      input: {
        cidadaoId,
        atendimentoIdDesconsiderar: atendimentoId,
      },
    },
    skip: isStartOuAtendimentoObservacao || isRegistroTardio,
  })

  const podeManterNaLista =
    isRegistroTardio || isStartOuAtendimentoObservacao || (!loading && !presenteOutroAtendimentoNaLista)

  useEffect(() => {
    !podeManterNaLista && onChange(ManterCidadaoLista.NAO)
  }, [podeManterNaLista, onChange])

  const podeFinalizarAtendimento =
    !isAtendimentoObservacao ||
    isObservacaoAndResponsavel ||
    podeFinalizarAtendimentoObservacao(acesso, tipoEstabelecimento)

  const showConduta =
    isObservacaoAndAuxiliar &&
    podeFinalizarAtendimento &&
    !isUndefinedOrNull(condutasName) &&
    manterCidadaoLista === ManterCidadaoLista.NAO

  const manterNaListaTooltipText = isStartObservacao
    ? TOOLTIP_START_OBSERVACAO
    : podeFinalizarAtendimento
    ? ''
    : isUpa
    ? TOOLTIP_NAO_RESPONSAVEL_PELA_OBSERVACAO_UPA
    : TOOLTIP_NAO_RESPONSAVEL_PELA_OBSERVACAO_UBS

  return (
    <FormControl label={labelPanel} required>
      <VFlow vSpacing={0.5}>
        <Grid>
          <Cell size={12}>
            <VFlow>
              <RadioFieldContainer
                name={name.manterCidadaoLista}
                value={ManterCidadaoLista.NAO}
                disabled={isStartObservacao || (isAtendimentoObservacao && !podeFinalizarAtendimento)}
                tooltipText={manterNaListaTooltipText}
              >
                <Text>Liberar cidadão</Text>
              </RadioFieldContainer>

              {showConduta && (
                <Paper style={classes.dadosAtendimento}>
                  <VFlow vSpacing={0.5}>
                    <Alert type='info' inline>
                      O registro da conduta será inserido no atendimento dos profissionais responsáveis.
                    </Alert>
                    <CondutaPanel
                      name={condutasName}
                      hasNotificacaoCasoSuspeito={hasNotificacaoCasoSuspeito}
                      hasEncaminhamentoExterno={hasEncaminhamentoExterno}
                      isObservacao={isStartOuAtendimentoObservacao}
                    />
                  </VFlow>
                </Paper>
              )}
            </VFlow>
          </Cell>
          <CheckPermission permission={Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir}>
            <Cell size={12}>
              <VFlow>
                <RadioFieldContainer
                  name={name.manterCidadaoLista}
                  value={ManterCidadaoLista.SIM}
                  disabled={!podeManterNaLista}
                  tooltipText={!podeManterNaLista ? TOOLTIP_NAO_MANTER_NA_LISTA : ''}
                >
                  {!isStartOuAtendimentoObservacao ? (
                    <Text>{labelManterCidadaoLista}</Text>
                  ) : (
                    <Text>
                      Manter cidadão na lista de atendimentos <strong>para observação</strong>
                    </Text>
                  )}
                </RadioFieldContainer>

                {manterCidadaoLista === ManterCidadaoLista.SIM && (
                  <>
                    <Paper style={classes.dadosAtendimento}>
                      <EncaminhamentoForm
                        name={name.atendimento}
                        agendamentosDia={agendamentosDia}
                        isStartOuAtendimentoObservacao={isStartOuAtendimentoObservacao}
                      />
                    </Paper>
                    <ErrorField name={name.atendimento} />
                  </>
                )}
              </VFlow>
            </Cell>
          </CheckPermission>
        </Grid>
      </VFlow>
    </FormControl>
  )
}

const createStyles = (theme: Theme) => ({
  dadosAtendimento: {
    padding: '1rem',
    borderColor: theme.pallete.gray.c60,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 'none',
    marginTop: '-1.1rem',
  },
})
