import { Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, ModalProps, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { getFirstGraphqlError } from 'components/error'
import { FooterButton } from 'components/footer-button'
import { information } from 'components/modals/information'
import { useInativarAtendimentoMutation } from 'graphql/hooks.generated'
import React from 'react'

interface RemoveRegistroConfirmModalProps extends ModalProps {
  atendimentoProfissionalId: ID
  justificativa: string

  onCancelar?(): void
  onConfirmar?(): void
  nomeCidadao: string
}

export function RemoveRegistroConfirmModal(props: RemoveRegistroConfirmModalProps) {
  const { onCancelar, onConfirmar, atendimentoProfissionalId, justificativa, nomeCidadao, ...rest } = props
  const alert = useAlert()

  const [inativarAtendimento, { loading }] = useInativarAtendimentoMutation()

  const handleConfirmar = async () => {
    try {
      await inativarAtendimento({
        variables: {
          input: {
            atendimentoProfissionalId,
            justificativa,
          },
        },
      })

      onConfirmar?.()
      alert('success', 'Registro removido com sucesso.')
    } catch (error) {
      const errorString =
        getFirstGraphqlError(error)?.message ?? 'Ocorreu um erro não identificado ao remover o atendimento.'

      information({
        title: 'Erro ao remover atendimento',
        body: errorString,
        type: 'danger',
      })()
    }
  }

  return (
    <Modal size='small' onClose={onCancelar} {...rest}>
      <ModalBody>
        <VFlow>
          <Heading level={2}>
            <HFlow alignItems='center'>
              <Icon icon='exclamationTriangleOutline' size={3} fill='danger' />
              Deseja remover esse registro do prontuário de {nomeCidadao}?
            </HFlow>
          </Heading>
          <Text>
            A informação de que esse registro foi removido será enviada para a base nacional.{' '}
            <strong>Os registros removidos poderão ser visualizados na Trilha de Auditoria do PEC e-SUS APS.</strong>
          </Text>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <FooterButton kind='normal' onClick={onCancelar} size='medium'>
            Cancelar
          </FooterButton>
          <FooterButton kind='danger' onClick={handleConfirmar} size='medium' disabled={loading}>
            Remover
          </FooterButton>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
