import { ConselhoClasseSelectModel, SexoSelectModel, UfSelectModel } from 'components/form'
import { EnderecoFieldGroupModel } from 'components/form/field'

export default interface ProfissionalFormModel {
  id?: ID
  conselhoClasse?: ConselhoClasseSelectModel
  cpf: string
  cns?: string
  dataNascimento?: string
  email?: string
  endereco?: EnderecoFieldGroupModel
  nomeCivil: string
  nomeSocial?: string
  numeroConselhoClasse?: string
  sexo?: SexoSelectModel
  telefone?: string
  ufEmissoraConselhoClasse?: UfSelectModel
}

export const SEXOS_INVALIDOS = ['AMBOS', 'NAO_INFORMADO', 'IGNORADO']
