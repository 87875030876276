/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Paper, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { toDate } from 'date-fns'
import { TipoMedicaoEnum } from 'graphql/types.generated'
import { cloneElement } from 'react'
import { dateAsDdMmYyyy } from 'util/date/formatDate'
import { MedicaoPeso } from 'view/atendimentos/detail/components/modals/medicoes/model-medicoes'
import { tipoMedicaoRecord } from 'view/atendimentos/detail/soap/aside/types/MedicaoModel'

interface PesoPaperProps {
  medicaoPeso?: MedicaoPeso
}

export const PesoPaper = (props: PesoPaperProps) => {
  const { medicaoPeso } = props
  const tipoMedicaoPeso = tipoMedicaoRecord[TipoMedicaoEnum.PESO]
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Paper style={styles.paper}>
      <HFlow hSpacing={0.5}>
        <div css={styles.icon}>
          {cloneElement(tipoMedicaoPeso?.icon, {
            style: { color: `${gray.c10}`, fill: `${gray.c10}`, width: '1.5rem', height: '1.5rem' },
          })}
        </div>
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'>Peso</Text>
          {medicaoPeso ? (
            <Text>
              {medicaoPeso.peso} kg - {dateAsDdMmYyyy(toDate(medicaoPeso.dataMedicao))}
            </Text>
          ) : (
            <Text fontStyle='italic'>Sem peso registrado</Text>
          )}
        </VFlow>
      </HFlow>
    </Paper>
  )
}

const createStyles = (theme: Theme) => ({
  icon: css`
    padding-top: 0.25rem;
    margin: auto;
  `,
  paper: css`
    border: 0;
    max-height: 3.125rem;
    min-width: 10.5rem;
    width: max-content;
    padding: 0.25rem 0.5rem;
    background: ${theme.pallete.surface.background};
  `,
})
