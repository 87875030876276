import { Grid, Text } from 'bold-ui'
import { FilterItem } from 'components/filteritem'
import React from 'react'

interface FilterTagsProps {
  tags: FilterTag[]
}

export type FilterTag = [string, string]

export const FilterTags = (props: FilterTagsProps) => {
  const { tags } = props

  return tags.some(isTagTruthy) ? (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={false}>
      {tags.map(([label, text]) => text && <FilterItem key={label} label={`${label}:`} value={text} />)}
    </Grid>
  ) : (
    <Text>Sem filtros ativos</Text>
  )
}

const isTagTruthy = ([_, text]: FilterTag) => !!text
