/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Cell, FormControl, Grid, HFlow, Icon, Link, Theme, Tooltip, VFlow } from 'bold-ui'
import { CheckboxField, ErrorField, Form, FormRenderProps, NumberField, PasswordField } from 'components/form'
import theme from 'config/theme'
import { FormState } from 'final-form'
import { useValidarConfiguracaoCertificadoMutation } from 'graphql/hooks.generated'
import { ServicosCertificadoEnum } from 'graphql/types.generated'
import { useMemo, useState } from 'react'
import { metaPath } from 'util/metaPath'
import { handleKeyDown } from 'view/atendimentos/detail/soap/util'

import { CertificadoSelectField } from './CertificadoSelectField'
import ConfiguracaoCertificadosFormModel from './ConfiguracoesCertificadosRootView'
import { InformacaoContraChaveCertificadoComponent } from './InformacaoContraChaveCertificadoComponent'
import { ServicosSelecionadosConfirmacaoModal } from './ServicosSelecionadosConfirmacaoModal'
import { configuracaoCertificadosCreateValidator } from './validator'

export interface ConfiguracaoCertificadoCreateViewProps {
  internetHabilitada: boolean
  handleSubmit: (values: ConfiguracaoCertificadosFormModel) => Promise<void>
  contraChave: string
  loading: boolean
}

interface ValidationErrors {
  rndsError?: String
  cadsusError?: String
}

const path = metaPath<ConfiguracaoCertificadosFormModel>()
const internetMessage = 'Não pode ser habilitada sem conexão com a internet'
const iconToolTipText = 'Selecione os serviços que serão habilitados com o certificado'

export function ConfiguracaoCertificadosCreateView({
  internetHabilitada,
  handleSubmit,
  contraChave,
  loading,
}: ConfiguracaoCertificadoCreateViewProps) {
  const [error, setError] = useState<ValidationErrors>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [servicosValidados, setServicosValidados] = useState<ServicosCertificadoEnum[]>()
  const [validateConnection] = useValidarConfiguracaoCertificadoMutation()
  const classes = useMemo(() => createStyles(theme), [])

  const renderForm = (formProps: FormRenderProps<ConfiguracaoCertificadosFormModel>) => {
    const handleModalClose = () => {
      setIsModalOpen(false)
    }

    const handleModalSubmit = () => {
      handleSubmit({ ...formProps.values, servicos: servicosValidados })
    }

    return (
      <form noValidate onKeyDown={handleKeyDown}>
        <ServicosSelecionadosConfirmacaoModal
          servicosSelecionados={formProps.values.servicos}
          servicosValidados={servicosValidados}
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          handleModalSubmit={handleModalSubmit}
          loading={loading}
        />
        <VFlow>
          {error && (
            <Alert type='danger' inline>
              {error.rndsError && (
                <span>
                  {error.rndsError} <br />
                </span>
              )}
              {error.cadsusError}
            </Alert>
          )}
          <VFlow>
            <HFlow style={{ gridGap: '0' }}>
              <FormControl label='Serviços' required style={{ width: 'auto' }}>
                <VFlow>
                  <Tooltip text={!internetHabilitada && internetMessage}>
                    <VFlow>
                      <CheckboxField
                        name={path.servicos}
                        value={ServicosCertificadoEnum.RNDS}
                        label='RNDS'
                        disabled={!internetHabilitada}
                      />
                      <CheckboxField
                        name={path.servicos}
                        value={ServicosCertificadoEnum.CADSUS}
                        label='CADSUS'
                        disabled={!internetHabilitada}
                      />
                    </VFlow>
                  </Tooltip>
                </VFlow>
              </FormControl>
              <HFlow
                alignItems='flex-start'
                style={{ position: 'absolute', width: '23rem', height: '1.5rem', marginLeft: '4.5rem' }}
              >
                <Tooltip text={iconToolTipText} placement='auto' style={{ display: 'block' }}>
                  <Icon css={classes.iconStyle} icon='infoCircleFilled' size={1} />
                </Tooltip>
              </HFlow>
            </HFlow>
            <ErrorField name={path.servicos} />
          </VFlow>
          <InformacaoContraChaveCertificadoComponent contraChave={contraChave} />
          <Grid alignItems='flex-start'>
            <Cell size={3}>
              <Tooltip text={!internetHabilitada && internetMessage}>
                <CertificadoSelectField
                  name={path.certificadoSelect}
                  label='Certificado'
                  disabled={!internetHabilitada}
                  required
                />
              </Tooltip>
            </Cell>
            <Cell size={3}>
              <Tooltip text={!internetHabilitada && internetMessage}>
                <PasswordField
                  name={path.senhaCertificado}
                  label='Senha'
                  required
                  disabled={!internetHabilitada}
                  maxLength={255}
                />
              </Tooltip>
            </Cell>

            {formProps.values.servicos?.includes(ServicosCertificadoEnum.RNDS) && (
              <Cell size={3}>
                <Tooltip text={!internetHabilitada && internetMessage}>
                  <NumberField
                    name={path.idSolicitante}
                    label='Identificador solicitante'
                    disabled={!internetHabilitada}
                    maxLength={20}
                    required
                  />
                </Tooltip>
              </Cell>
            )}

            <Cell size={3} style={{ marginTop: '1.6rem' }}>
              <Tooltip text={!internetHabilitada && internetMessage}>
                <Button
                  kind='primary'
                  size='small'
                  disabled={!internetHabilitada}
                  onClick={formProps.handleSubmit}
                  loading={loading}
                >
                  Habilitar
                </Button>
              </Tooltip>
            </Cell>
          </Grid>
        </VFlow>
      </form>
    )
  }

  const innerHandleSubmit = ({
    servicos,
    senhaCertificado,
    idSolicitante,
    certificadoSelect,
  }: ConfiguracaoCertificadosFormModel) => {
    return validateConnection({
      variables: {
        configuracaoCertificadoInput: {
          servicos: servicos,
          senhaCertificado: senhaCertificado,
          pathCertificado: certificadoSelect.path,
          idSolicitante: idSolicitante,
        },
      },
    }).then(({ data: { validarConfiguracaoCertificado } }) => {
      setServicosValidados(validarConfiguracaoCertificado)
      setError(null)
      if (servicos.every((item) => validarConfiguracaoCertificado.includes(item))) {
        handleSubmit({
          servicos: servicos,
          senhaCertificado: senhaCertificado,
          idSolicitante: idSolicitante,
          certificadoSelect: certificadoSelect,
        })
      } else {
        setIsModalOpen(true)
      }
    })
  }

  const onSubmitFailed = (formState: FormState<ConfiguracaoCertificadosFormModel>) => {
    Form.defaultProps.onSubmitFailed(formState)
    setError(() => formState.submitErrors)
  }

  return (
    <VFlow>
      <Alert type='info' inline>
        <div>
          <b>1.</b>
          {' Faça o download do certificado do município através da área restrita do '}
          <Link href='https://acesso-egestoraps.saude.gov.br/login' target='_blank'>
            e-Gestor AB
          </Link>
          .
        </div>
        <div>
          <b>2.</b>
          {' O administrador da instalação deve incluir o certificado (arquivo .pfx ou .p12) ' +
            'com a chave privada na pasta "chaves" no instalador do PEC (no Windows: c:Program ' +
            'Files/e-SUS/webserver/chaves e no Linux: /opt/e-SUS/webserver/chaves).'}
        </div>

        <div>
          <b>3.</b>
          {' Após incluído no servidor, selecione o certificado no campo ' +
            '"certificado" e insira a senha e o identificador solicitante para habilitar.'}
        </div>
      </Alert>
      <Form<ConfiguracaoCertificadosFormModel>
        render={renderForm}
        onSubmit={innerHandleSubmit}
        onSubmitFailed={onSubmitFailed}
        validate={configuracaoCertificadosCreateValidator}
        validateOnBlur={false}
      />
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  iconStyle: css`
    display: block;
    &:hover: {
      color: ${theme.pallete.primary.main};
    }
  `,
})
