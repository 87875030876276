/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, HFlow, Text, Theme, useTheme } from 'bold-ui'
import { SimNaoRadioGroupField } from 'components/form'
import { RequiredLabel } from 'components/form/final-form/RequiredLabel/RequiredLabel'
import { isUndefinedOrNull } from 'util/checks'
import { Meta, MetaArray } from 'util/metaPath'

interface SimNaoAccordionItemProps {
  name: string | MetaArray<any> | Meta<any>
  title: string
  required?: boolean
  borderBottom?: boolean
  clearable?: boolean
  style?: {
    hFlow?: ExternalStyles
    vFlow?: ExternalStyles
    radioField?: ExternalStyles
  }
  children?: React.ReactNode
}

export const SimNaoAccordionItem = ({
  name,
  title,
  required = false,
  borderBottom = false,
  clearable = false,
  style,
  children,
}: SimNaoAccordionItemProps) => {
  const theme = useTheme()
  const hasChildren = !isUndefinedOrNull(children)
  const defaultStyles = createStyles(theme, borderBottom, hasChildren)

  return (
    <HFlow alignItems='center' justifyContent='space-between' style={defaultStyles.accordionItem}>
      <div css={defaultStyles.labelContainer}>
        {required ? <RequiredLabel fontWeight='bold' label={title} /> : <Text fontWeight='bold'>{title}</Text>}
      </div>
      <div css={defaultStyles.radioContainer}>
        <SimNaoRadioGroupField name={name} clearable={clearable} style={style} />
      </div>
      {/* TODO NP: A princípio há somente os SelectFields da seção de Informações Sociodemográficas como children, coloquei o css fixo por que o style por prop não funcionou.
         Daria para evoluir o Bold e dar acesso a style para selectfields com multiple, ou podemos tentar colocar esse componente em um Grid.*/}
      {hasChildren && (
        <div
          css={css`
            width: 416px;
            margin-right: 1rem;
          `}
        >
          {children}
        </div>
      )}
    </HFlow>
  )
}

const createStyles = (theme: Theme, borderBottom: boolean, hasChildren: boolean) => ({
  accordionItem: css`
    ${borderBottom ? `border-bottom: 1px solid ${theme.pallete.divider}` : ''};
    height: 4.5rem;
  `,
  radioContainer: css`
    width: 170px;
    height: 50px;
    margin-top: 1rem;
    margin-left: 1rem;
  `,
  labelContainer: css`
    margin-left: 20px;
    width: ${hasChildren ? '340px' : '680px'};
  `,
})
