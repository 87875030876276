import { PageLoading } from 'components/loading'
import { css } from 'emotion'
import React from 'react'
import { CypressProps } from 'types/cypress'

export interface ContentCardSectionProps extends CypressProps {
  children: React.ReactNode
  isLoading?: boolean
  messageLoading?: string
  canRender?: boolean
}

export function ContentCardSection({ canRender = true, ...props }: ContentCardSectionProps) {
  const { isLoading, children, messageLoading, dataTestId } = props

  return (
    <>
      {isLoading && <PageLoading message={messageLoading || 'Carregando dados'} />}
      {!isLoading && (
        <div
          className={css`
            padding: 0.5rem;
            display: ${canRender ? 'block' : 'none'};
          `}
          data-testid={dataTestId}
        >
          {children}
        </div>
      )}
    </>
  )
}

export const getAsideCardStyles = () => ({
  ellipsesText: css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  `,
})
