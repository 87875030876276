/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, HFlow, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Ellipsis } from 'components/Ellipsis'
import { HLabel } from 'components/HLabel'
import { Cpf, Telefone } from 'components/label'
import { HideOnScreenSize } from 'components/responsive'
import { useAtendimentoHeaderCidadaoQuery } from 'graphql/hooks.generated'
import { Fragment } from 'react'
import { identidadeGenero, sexo } from 'types/enums'
import { humanizeAge } from 'util/date/humanize-age'

export interface AtendimentoHeaderCidadaoSectionProps {
  cidadaoId: ID
  dataAtendimento?: number
  showTelefoneAndUnidade: boolean
}

export const AtendimentoHeaderCidadaoSection = (props: AtendimentoHeaderCidadaoSectionProps) => {
  const { cidadaoId, dataAtendimento, showTelefoneAndUnidade } = props

  const { data: { cidadao } = {} } = useAtendimentoHeaderCidadaoQuery({
    variables: { cidadaoId, fetchCidadaoVinculacaoEquipeAndTelefones: showTelefoneAndUnidade },
  })

  const telefone = cidadao?.telefoneCelular || cidadao?.telefoneResidencial || cidadao?.telefoneContato

  if (!cidadao) return null

  return (
    <Grid gapVertical={0}>
      <Cell size={12}>
        <Grid alignItems='baseline' gap={0.5} gapVertical={0}>
          <Cell>
            <Heading level={2}>{(cidadao.nomeSocial ?? cidadao.nome).titleCase()}</Heading>
          </Cell>
          {cidadao.nomeSocial && (
            <Cell>
              <Text fontSize={1} fontWeight='bold'>
                (Nome social)
              </Text>
            </Cell>
          )}
          <Cell>
            <Text fontSize={0.9}>
              {cidadao.nomeSocial ? `${cidadao.nome.titleCase()} ` : ''}{' '}
              {cidadao.nomeTradicional && `|${cidadao.nomeTradicional.titleCase()}`}|&nbsp;
              {`Sexo ${sexo[cidadao.sexo]}`}
            </Text>
            {cidadao.identidadeGeneroDbEnum && (
              <Text fontSize={0.9}>({identidadeGenero[cidadao.identidadeGeneroDbEnum]})</Text>
            )}
          </Cell>
          {cidadao.faleceu && (
            <Cell>
              <Tag style={styles.tag}>Óbito</Tag>
            </Cell>
          )}
          {!cidadao.ativo && (
            <Cell>
              <Tag style={styles.tag}>Inativo</Tag>
            </Cell>
          )}
          {cidadao.unificado && (
            <Cell>
              <Tag style={styles.tag}>Cadastro unificado</Tag>
            </Cell>
          )}
          {cidadao?.unificacaoBase && (
            <Cell>
              <Tooltip text={`Cadastro originado após unificação da Base ${cidadao.unificacaoBase}`}>
                <Tag style={styles.tag}>Base {cidadao.unificacaoBase.titleCase()}</Tag>
              </Tooltip>
            </Cell>
          )}
        </Grid>
      </Cell>

      <Cell size={12}>
        <Grid wrap={false}>
          <Cell>
            <VFlow vSpacing={0}>
              {cidadao.dataNascimento && (
                <Text
                  style={css`
                    white-space: nowrap;
                  `}
                >
                  {humanizeAge(cidadao.dataNascimento, dataAtendimento)}
                  {' | Nasceu em '}
                  <DateTime format='DD/MM/YYYY' value={cidadao.dataNascimento} />
                </Text>
              )}
              {showTelefoneAndUnidade && (
                <HLabel title='Telefone'>
                  <Telefone value={telefone}></Telefone>
                </HLabel>
              )}
            </VFlow>
          </Cell>
          <Cell>
            <VFlow vSpacing={0}>
              <HFlow hSpacing={1}>
                <HLabel title='CPF'>
                  <Cpf value={cidadao?.cpf} />
                </HLabel>
                <HLabel title='CNS'>{cidadao?.cns}</HLabel>
                <Ellipsis title={cidadao?.nomeMae?.titleCase()} maxLines={1}>
                  <HLabel title={<NomeMaeTitle />} data-cy='CidadaoCabecalho.nomeMae'>
                    {cidadao?.nomeMae?.titleCase()}
                  </HLabel>
                </Ellipsis>
              </HFlow>
              {showTelefoneAndUnidade && (
                <Ellipsis title={cidadao?.cidadaoVinculacaoEquipe?.unidadeSaude?.nome.titleCase()} maxLines={3}>
                  <HLabel title='Unidade responsável'>
                    {cidadao?.cidadaoVinculacaoEquipe?.unidadeSaude?.nome.titleCase()}
                  </HLabel>
                </Ellipsis>
              )}
            </VFlow>
          </Cell>
        </Grid>
      </Cell>
    </Grid>
  )
}

function NomeMaeTitle() {
  return (
    <Fragment>
      <HideOnScreenSize up='lg'>Mãe</HideOnScreenSize>
      <HideOnScreenSize down='lg'>Nome da mãe</HideOnScreenSize>
    </Fragment>
  )
}

const styles = {
  tag: css`
    &:not(:last-of-type) {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  `,
}
