import { AtividadeColetivaQueryInput } from 'graphql/types.generated'
import { AtividadeColetivaFilterFormModel } from 'view/atividade-coletiva/model-atividadeColetiva'

const convertNomeCnsCpf = (nomeCnsCpf: string | undefined) => {
  const trimmed = nomeCnsCpf?.trim()

  return trimmed?.length < 3 ? undefined : trimmed
}

export const convertAtividadeColetivaFilterFormModelToInput = (
  model: AtividadeColetivaFilterFormModel,
  profissionalCpf: string
): AtividadeColetivaQueryInput => ({
  isReuniao: !!model.isReuniao,
  equipeId: model.equipe?.id,
  isProcessado: model.isProcessado,
  nomeCnsCpfProfissionalResponsavel: model.isSomenteRegistrosProprios
    ? profissionalCpf
    : convertNomeCnsCpf(model.nomeCnsCpfProfissionalResponsavel),
  periodo: model.periodo,
  tipoAtividade: model.tipoAtividade,
  pageParams: model.pageParams,
})
