import { Alert, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import {
  Action,
  CuidadoCompartilhadoQueryFilterEnum,
  CuidadosCompartilhadoQueryInput,
  StatusCuidadoCompartilhadoEnum,
} from 'graphql/types.generated'
import React from 'react'

import {
  CuidadoCompartilhadoItemActions,
  CuidadoCompartilhadoSort,
  CuidadoCompartilhadoSortEnum,
  ListaCuidadoCompartilhadoFilterModel,
} from '../model-cuidadoCompartilhado'
import { ListaCuidadoCompartilhadoHeader } from './ListaCuidadoCompartilhadoHeader'
import { ListaCuidadoCompartilhadoView } from './ListaCuidadoCompartilhadoView'

const filterToQueryInput = (value: ListaCuidadoCompartilhadoFilterModel): CuidadosCompartilhadoQueryInput => {
  return {
    query: value.query,
    cboId: value.cbo?.id,
    status: value.status,
    dataInicio: value.periodo?.startDate,
    dataFim: value.periodo?.endDate,
    unidadeSaudeId: value.unidadeSaude?.id,
    somenteSemExecutante: true,
    solicitadosPorProfissionalId: value.profissionalSolicitante?.id,
    executadosPelaMinhaEquipe: false,
    solicitadosPelaMinhaEquipe: false,
    somenteUnidadeExecutante: true,
    somenteUnidadesBasicas: false,
    pageParams: { ...value.pageParams, sort: CuidadoCompartilhadoSort[value.pageParams.sort] },
  }
}

const resolveDiscutirAction = (actions: CuidadoCompartilhadoItemActions): Action => actions?.discutirCasoExecutante

const FILTER_DEFAULT: ListaCuidadoCompartilhadoFilterModel = {
  status: [StatusCuidadoCompartilhadoEnum.AGUARDANDO, StatusCuidadoCompartilhadoEnum.RESPONDIDO],
  periodo: null,
  profissionalExecutante: null,
  profissionalSolicitante: null,
  unidadeSaude: null,
  equipe: [],
  cbo: null,
  switch: null,
}

export const ListaCuidadoCompartilhadoUnidadeView = () => {
  const filterToQuery = (value: ListaCuidadoCompartilhadoFilterModel) => filterToQueryInput(value)

  return (
    <>
      <ListaCuidadoCompartilhadoHeader />
      <PageContent fluid type='transparent'>
        <VFlow vSpacing={0.5}>
          <Alert type='info' inline>
            A lista apresenta apenas registros nos quais ainda não há profissional executante.
          </Alert>
          <ListaCuidadoCompartilhadoView
            filterDefault={FILTER_DEFAULT}
            initialSort={CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_EXECUTANTE}
            itemsToSort={[CuidadoCompartilhadoSortEnum.CLASSIFICACAO_DE_PRIORIDADE_EXECUTANTE]}
            filterSwitchLabel={null}
            somenteSemExecutante={true}
            role={CuidadoCompartilhadoQueryFilterEnum.EXECUTANTE}
            filterToQueryInput={filterToQuery}
            resolveDiscutirAction={resolveDiscutirAction}
          />
        </VFlow>
      </PageContent>
    </>
  )
}
