import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { Form, FormRenderProps, SubmitButton, TextAreaField } from 'components/form'
import React from 'react'
import { metaPath } from 'util/metaPath'
import { createValidator, required } from 'util/validation'

const meta = metaPath<JustificativaFormModel>()

export interface JustificativaModalProps {
  isModalOpen: boolean
  heading: string
  text: string
  onConfirm(values: JustificativaFormModel): void
  onCancel(): void
  onReject(): void
}

export interface JustificativaFormModel {
  justificativa: string
}

const validate = createValidator<JustificativaFormModel>({
  justificativa: [required],
})

export const JustificativaModal = (props: JustificativaModalProps) => {
  const { isModalOpen, heading, text, onConfirm, onCancel, onReject } = props

  const justificativaModalForm = (formProps: FormRenderProps) => {
    return (
      <Modal open={isModalOpen} onClose={onReject} size='auto' closeOnBackdropClick={false}>
        <ModalBody>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2.45rem' }}>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={3} style={{ marginRight: '1rem' }} />
            <VFlow vSpacing={0}>
              <Heading level={1}>{heading}</Heading>
            </VFlow>
          </div>
          <TextAreaField
            style={{ resize: 'none', height: '5rem' }}
            name={meta.justificativa}
            label={text}
            maxLength={500}
            required
            dataTestId='JustificativaModal.justificativa'
          />
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={onCancel}>Cancelar</Button>
            <SubmitButton
              title='Salvar'
              kind='primary'
              handleSubmit={formProps.handleSubmit}
              dataTestId='JustificativaModal.submit'
            >
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return <Form render={justificativaModalForm} validate={validate} onSubmit={onConfirm} />
}
