import { Text, VFlow } from 'bold-ui'
import React from 'react'

import { NewsHeadingSection } from './NewsHeadingSection'

export function NewsInternalEstabilizacaoV53() {
  return (
    <VFlow>
      <NewsHeadingSection title='Versão 5.3.19' id='estabilizacao-5-3-19'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Atualizações globais no sistema para assegurar compatibilidade com a nova opção de sexo "Indeterminado".
          </Text>
          <Text component='li'>
            Agora é possível visualizar doses fracionadas de medicamentos por turno no histórico clínico do cidadão para
            atendimentos individuais.
          </Text>
          <Text component='li'>
            Ajustes no histórico clínico para exibir corretamente o nome social dos profissionais.
          </Text>
          <Text component='li'>
            Correção no campo de modalidade assistencial transmitido para a RNDS no Registro de Atendimento Clínico.
          </Text>
          <Text component='li'>Inclusão de um link para a página de "Links Úteis" na tela inicial do sistema.</Text>
          <Text component='li'>
            Implementação de restrição para impedir a seleção da opção "Manter cidadão na lista de atendimento" em
            registros de atendimentos realizados em datas anteriores.
          </Text>
          <Text component='li'>Inclusão do peso do cidadão na modal de prescrição de medicamentos.</Text>
          <Text component='li'>
            Adição de alerta para informar sobre alergias recentemente registradas, garantindo que o profissional
            visualize avisos de alergia durante o atendimento.
          </Text>
          <Text component='li'>
            As quebras de linha inseridas no campo de recomendações agora são exibidas corretamente na impressão das
            prescrições de medicamentos.
          </Text>
          <Text component='li'>
            Atualização das regras para registro da aplicação das vacinas VIP (código 22), dT (código 25), VOP (código
            28) e dTpa (código 57), com os ajustes necessários no calendário vacinal infantil.
          </Text>
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}
