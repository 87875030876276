/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow } from 'bold-ui'
import { DateField } from 'components/form'
import { InfoIcon } from 'components/InfoIcon'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'

import { DumPaper } from '../../pre-natal/components/DumPaper'
import { TipoPreNatal } from '../../pre-natal/model-preNatal'

interface DumObjetivoFormProps {
  name: MetaPath<string>
  tipoPreNatal: TipoPreNatal
}

export function DumObjetivoForm(props: DumObjetivoFormProps) {
  const { name, tipoPreNatal } = props

  const {
    cidadao: { dataNascimento, ultimaDum },
    atendimentoProfissional: { iniciadoEm },
  } = useAtendimentoContext()

  const isDumRequired =
    tipoPreNatal === TipoPreNatal.PRIMEIRO_ATENDIMENTO_PRE_NATAL ||
    (tipoPreNatal === TipoPreNatal.CONTINUACAO_PRE_NATAL && isUndefinedOrNull(ultimaDum))

  return (
    <HFlow>
      <DateField
        name={name}
        label={
          <Fragment>
            DUM
            <InfoIcon
              icon='infoCircleFilled'
              text='Preencher mesmo se a cidadã estiver com dúvida.'
              style={css`
                padding: 0.2rem 0 0 0.2rem;
              `}
            />
          </Fragment>
        }
        minDate={toDate(dataNascimento)}
        maxDate={iniciadoEm}
        required={isDumRequired}
        dataTestId='DumObjetivoForm.dum'
      />
      <DumPaper />
    </HFlow>
  )
}
