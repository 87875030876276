import { css } from '@emotion/core'
import { Alert, FormControl, Heading, HFlow, Modal, ModalBody, ModalFooter, ModalProps, Text, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import { ErrorField, Form, FormRenderProps, TextAreaField } from 'components/form'
import { RadioFieldContainer } from 'components/form/field/RadioFieldContainer'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { LoadingIndicator } from 'components/loading'
import { useCabecalhoModalRetificacaoAtendimentoQuery } from 'graphql/hooks.generated'
import React from 'react'
import { formatDate, formatHoursMinutes } from 'util/date/formatDate'
import { metaPath } from 'util/metaPath'

import { RetificacaoAtendimentoModel, RetificacaoAtendimentoOpcaoEnum } from '../model-retificacaoAtendimento'
import { validateRetificacaoAtendimentoModal } from '../validator-retificacaoAtendimento'

export const meta = metaPath<RetificacaoAtendimentoModel>()

interface JustificativaRetificacaoAtendimentoModalProps extends Omit<ModalProps, 'onSubmit'> {
  cidadaoId: ID
  dataAtendimento: Instant
  onCancelar(): void
  onSubmit(values: RetificacaoAtendimentoModel): void
  loading: boolean
}

export function JustificativaRetificacaoAtendimentoModal(props: JustificativaRetificacaoAtendimentoModalProps) {
  const { cidadaoId, dataAtendimento, onCancelar, onSubmit, loading, ...rest } = props

  const renderForm = ({ handleSubmit }: FormRenderProps<RetificacaoAtendimentoModel>) => (
    <form noValidate>
      <Modal size='large' onClose={onCancelar} {...rest}>
        <ModalBody>
          <VFlow>
            <Heading level={1}>Retificar registro do atendimento do procedimento</Heading>
            <Alert type='info' styles={styles.alert}>
              Todos os registros adicionados, editados e removidos da retificação serão enviados para a base nacional.{' '}
              <strong>As alterações poderão ser visualizadas na Trilha de Auditoria do PEC e-SUS APS.</strong>
            </Alert>
            <CabecalhoModalRetificacaoAtendimento cidadaoId={cidadaoId} dataAtendimento={dataAtendimento} />
            <FormControl label='Tipo de retificação' required>
              <VFlow vSpacing={0.5}>
                <RadioFieldContainer
                  label='Editar registro'
                  name={meta.tipo}
                  value={RetificacaoAtendimentoOpcaoEnum.EDITAR_REGISTRO}
                  required
                />
                <RadioFieldContainer
                  label='Remover registro do prontuário'
                  name={meta.tipo}
                  value={RetificacaoAtendimentoOpcaoEnum.REMOVER_REGISTRO}
                  required
                />
                <ErrorField name={meta.tipo} />
              </VFlow>
            </FormControl>
            <TextAreaField
              label='Justificativa'
              name={meta.justificativa}
              maxLength={500}
              required
              style={styles.textAreaField}
            />
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <FooterButton kind='normal' onClick={onCancelar} size='medium'>
              Cancelar
            </FooterButton>
            <FooterButton kind='primary' onClick={handleSubmit} size='medium' loading={loading}>
              Retificar
            </FooterButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    </form>
  )

  return (
    <Form<RetificacaoAtendimentoModel>
      render={renderForm}
      onSubmit={onSubmit}
      validate={validateRetificacaoAtendimentoModal}
    />
  )
}

interface CabecalhoModalRetificacaoAtendimentoProps {
  cidadaoId: ID
  dataAtendimento: Instant
}

function CabecalhoModalRetificacaoAtendimento(props: CabecalhoModalRetificacaoAtendimentoProps) {
  const { cidadaoId, dataAtendimento } = props
  const {
    data: { cidadao },
    loading,
  } = useCabecalhoModalRetificacaoAtendimentoQuery({ variables: { cidadaoId } })

  const dataAtendimentoFormatted = formatDate(dataAtendimento)
  const horarioAtendimento = formatHoursMinutes(dataAtendimento)

  return loading ? (
    <LoadingIndicator />
  ) : (
    <VFlow vSpacing={0}>
      <Heading level={3}>{(cidadao.nomeSocial ?? cidadao.nome).titleCase()}</Heading>
      <HFlow hSpacing={1}>
        <HLabel title='CPF'>
          <Cpf value={cidadao.cpf} />
        </HLabel>
        <HLabel title='CNS'>{cidadao.cns}</HLabel>
        <HLabel title='Nome da mãe'>{cidadao.nomeMae?.titleCase()}</HLabel>
      </HFlow>
      <Text>
        Atendimento realizado no dia {dataAtendimentoFormatted} às {horarioAtendimento}
      </Text>
    </VFlow>
  )
}

const styles = {
  alert: {
    container: css`
      padding: 0.5rem;
    `,
    wrapper: css`
      padding: 0;
    `,
  },
  textAreaField: css`
    height: 5rem;
    resize: none;
  `,
}
