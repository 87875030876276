import { css } from '@emotion/core'
import { Cell, Grid, HFlow, Theme } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { RichTextField, SimNaoEnum, SimNaoRadioGroupField } from 'components/form'
import theme from 'config/theme'
import { parseISO } from 'date-fns'
import {
  FaixaEtariaConsumoAlimentar,
  TipoAtendimentoProfissional,
  TipoEstabelecimentoEnum,
} from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React, { useMemo } from 'react'
import { MetaPath } from 'util/metaPath'

import { MedicoesPanel, MedicoesPanelModel } from '../../../components/MedicoesPanel/MedicoesPanel'
import { PeriodoGestacaoModel } from '../../components/modals/types/PeriodoGestacaoModel'
import { AgendarProximasConsultasSectionModel } from '../finalizacao/components/proximas-consultas/AgendarProximasConsultasSection'
import { PreNatalFormModel, TipoPreNatal } from '../pre-natal/model-preNatal'
import { grupoCboMedicoes, grupoCboResultadosExames } from './acessos'
import { DumObjetivoForm } from './components/DumObjetivoForm'
import { NecessidadesEspeciaisField } from './components/NecessidadesEspeciaisField'
import {
  SwitchButtonObjetivoForm,
  SwitchButtonObjetivoFormModel,
  SwitchButtonObjetivoFormProps,
} from './components/SwitchButtonObjetivoForm'
import { MarcadoresConsumoAlimentarPanel } from './marcadores-consumo-alimentar/MarcadoresConsumoAlimentarPanel'
import { MarcadoresConsumoPanelModel } from './marcadores-consumo-alimentar/model'
import { calculateFaixaEtariaMarcadoresConsumoAlimentar } from './marcadores-consumo-alimentar/utils'
import { ResultadosExamesModel } from './resultados-exames/model-resultadosExames'
import { ResultadosExamesRootView } from './resultados-exames/ResultadosExamesRootView'

export interface ObjetivoFormModel {
  texto?: string
  dum?: string
  vacinacaoEmDia?: SimNaoEnum
  medicoes?: MedicoesPanelModel
  marcadoresConsumoAlimentar?: MarcadoresConsumoPanelModel
  necessidadesEspeciais?: boolean
  resultadosExames?: ResultadosExamesModel
  atendimentosEspecificos?: SwitchButtonObjetivoFormModel
}

export interface ObjetivoFormProps extends Omit<SwitchButtonObjetivoFormProps, 'name' | 'metasPreNatal'> {
  name: MetaPath<ObjetivoFormModel>
  isAtendimentoOdonto: boolean
  isAtendimentoProcedimentos: boolean
  metaPreNatal: MetaPath<PreNatalFormModel>
  metaAgendarProximasConsultas: MetaPath<AgendarProximasConsultasSectionModel>
  gestacoes: PeriodoGestacaoModel[]
  hasProblemaComCiapW78AtivoPersistido: boolean
}

function ObjetivoForm(props: ObjetivoFormProps) {
  const {
    name,
    cidadao,
    dataAtendimento,
    prontuarioId,
    metaPreNatal,
    metaAgendarProximasConsultas,
    tipoPreNatal,
    metaProblemasCondicoes,
    gestacoes,
    isAtendimentoOdonto,
    isAtendimentoProcedimentos,
    hasProblemaComCiapW78AtivoPersistido,
  } = props

  const {
    cidadao: { isGestante, dataNascimento },
    atendimentoProfissional: { iniciadoEm, tipo: tipoAtendimentoProfissional },
    tipoEstabelecimento,
    observacao,
    isRetificacao,
  } = useAtendimentoContext()

  const showDum = useMemo(() => {
    return (
      !isAtendimentoProcedimentos &&
      cidadao.informarDum &&
      (!isGestante || tipoPreNatal === TipoPreNatal.PRIMEIRO_ATENDIMENTO_PRE_NATAL)
    )
  }, [cidadao.informarDum, isAtendimentoProcedimentos, isGestante, tipoPreNatal])

  const shouldShowMCA =
    !(tipoAtendimentoProfissional === TipoAtendimentoProfissional.VACINACAO) &&
    !(isAtendimentoOdonto && tipoEstabelecimento === TipoEstabelecimentoEnum.CEO)

  const categoriaMarcadoresConsumoAlimentar: FaixaEtariaConsumoAlimentar = useMemo(
    () =>
      shouldShowMCA && calculateFaixaEtariaMarcadoresConsumoAlimentar(parseISO(dataNascimento), new Date(iniciadoEm)),
    [dataNascimento, iniciadoEm, shouldShowMCA]
  )

  const classes = createStyles(theme)

  return (
    <Grid>
      <Cell size={12}>
        <RichTextField
          name={name.texto}
          maxLength={4000}
          placeholder='Insira as demais informações relacionadas ao exame físico.'
          showModifiedStatus={isRetificacao}
        />
      </Cell>

      <Cell size={12} alignSelf='flex-start'>
        <SwitchButtonObjetivoForm
          name={name.atendimentosEspecificos}
          metasPreNatal={{
            metaDum: name.dum,
            metaPreNatal,
            metaAgendarProximasConsultas,
          }}
          cidadao={cidadao}
          prontuarioId={prontuarioId}
          dataAtendimento={dataAtendimento}
          tipoPreNatal={tipoPreNatal}
          metaProblemasCondicoes={metaProblemasCondicoes}
          hasProblemaComCiapW78AtivoPersistido={hasProblemaComCiapW78AtivoPersistido}
        />
      </Cell>

      {showDum && (
        <Cell size={8}>
          <DumObjetivoForm name={name.dum} tipoPreNatal={tipoPreNatal} />
        </Cell>
      )}
      <CheckPermission permission={grupoCboMedicoes.adicionar}>
        <Cell size={12}>
          <MedicoesPanel
            name={name.medicoes}
            dataAtendimento={iniciadoEm}
            cidadao={cidadao}
            gestacoes={gestacoes}
            style={classes.medicoesPanelStyle}
          />
        </Cell>
      </CheckPermission>

      {shouldShowMCA && (
        <Cell size={12}>
          <MarcadoresConsumoAlimentarPanel
            name={name.marcadoresConsumoAlimentar}
            faixaEtariaMCA={categoriaMarcadoresConsumoAlimentar}
            atendimentoObservacao={observacao}
          />
        </Cell>
      )}

      <Cell size={12}>
        <HFlow hSpacing={1}>
          <SimNaoRadioGroupField name={name.vacinacaoEmDia} label='Vacinação em dia?' clearable={true} />
        </HFlow>
      </Cell>

      {isAtendimentoOdonto && (
        <Cell size={12}>
          <NecessidadesEspeciaisField name={name.necessidadesEspeciais} label='Paciente com necessidades especiais' />
        </Cell>
      )}
      {!isAtendimentoProcedimentos && (
        <CheckPermission permission={grupoCboResultadosExames.adicionar}>
          <Cell size={12}>
            <ResultadosExamesRootView
              name={name.resultadosExames}
              prontuarioId={prontuarioId}
              cidadaoSexo={cidadao.sexo}
              cidadaoDataNascimento={cidadao.dataNascimento}
              cidadaoIdentidadeGeneroDbEnum={cidadao.identidadeGeneroDbEnum}
            />
          </Cell>
        </CheckPermission>
      )}
    </Grid>
  )
}

export default React.memo(ObjetivoForm)

const createStyles = (theme: Theme) => ({
  medicoesPanelStyle: css`
    margin: 0;
    border-radius: 2px;
    border: 1px solid ${theme.pallete.gray.c40};
    box-shadow: ${theme.shadows.outer[10]};
  `,
})
