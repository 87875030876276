/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Icon, Text, VFlow } from 'bold-ui'
import { TipoVisualizacaoBuscaAtivaVacinacaoEnum } from 'graphql/types.generated'
import { useIsOverflowing } from 'hooks/useIsOverflowing'
import { isNumber } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { LinkVerEquipesVinculadasView } from 'view/vinculacao-equipes/LinkVerEquipesVinculadasView'

import { BuscaAtivaVacinacaoFormModel, tipoVisualizacaoRecord } from '../model-buscaAtivaVacinacao'
import { faixaEtariaFormatter } from '../utils-buscaAtivaVacinacao'
import { BuscaAtivaFilterItem } from './BuscaAtivaFilterItem'

export interface BuscaAtivaVacinacaoActiveFiltersProps {
  filters: BuscaAtivaVacinacaoFormModel
}

export default function BuscaAtivaVacinacaoActiveFilters({ filters }: BuscaAtivaVacinacaoActiveFiltersProps) {
  const { grupoAlvo, status, equipe, microareas, faixaEtaria, filtrosAvancados, tipoVisualizacao } = filters

  const [isExpanded, setIsExpanded] = useState(false)

  const bigCellRef = useRef<HTMLDivElement>()

  const isExpandable = useIsOverflowing(bigCellRef, 'vertical')

  const isToggleEnabled = isExpandable || isExpanded

  const iconAngleState = isExpanded ? 'angleUp' : 'angleDown'

  const expandedLineClamp = isExpanded ? 4 : 1

  const classes = createStyles(expandedLineClamp)

  useEffect(() => {
    filters && setIsExpanded(false)
  }, [filters])

  const handleClick = () => {
    if (isToggleEnabled) setIsExpanded((state) => !state)
  }

  return (
    <VFlow vSpacing={0.5}>
      <Grid alignItems='center' justifyContent='space-between' gap={0.25} gapVertical={0.25} wrap>
        <Cell size={10} style={classes.bigCell}>
          <div ref={bigCellRef}>
            {tipoVisualizacao && (
              <BuscaAtivaFilterItem
                label={`Vacinas d${
                  tipoVisualizacao === TipoVisualizacaoBuscaAtivaVacinacaoEnum.CALENDARIO_VACINAL ? 'o' : 'a'
                }`}
                value={`${tipoVisualizacaoRecord[tipoVisualizacao].toLowerCase()}`}
                textStyle={classes.boldText}
              />
            )}
            {microareas && <BuscaAtivaFilterItem label='Microáreas:' value={microareas.join(', ')} />}
            {grupoAlvo && <BuscaAtivaFilterItem label='Grupo-alvo:' value={grupoAlvo.descricao} />}
            {isNumber(faixaEtaria.firstValue || faixaEtaria.secondValue) && (
              <BuscaAtivaFilterItem label='Faixa etária:' value={faixaEtariaFormatter(faixaEtaria)} />
            )}
            {status && <BuscaAtivaFilterItem label='Status da vacina:' value={status.descricao} />}
            {filtrosAvancados?.imunobiologicos?.isNotEmpty() && (
              <BuscaAtivaFilterItem
                label='Imunobiológico:'
                value={filtrosAvancados.imunobiologicos.map((imuno) => imuno.sigla).join(', ')}
              />
            )}
            {filtrosAvancados?.doses?.isNotEmpty() && (
              <BuscaAtivaFilterItem label='Dose:' value={filtrosAvancados.doses.map((dose) => dose.sigla).join(', ')} />
            )}
            {filtrosAvancados?.gruposAtendimento?.isNotEmpty() && (
              <BuscaAtivaFilterItem
                label='Grupo de atendimento:'
                value={filtrosAvancados.gruposAtendimento.map((grupo) => grupo.nome.capitalize()).join(', ')}
              />
            )}
            {filtrosAvancados?.tipoLogradouro && (
              <BuscaAtivaFilterItem
                label='Tipo de logradouro:'
                value={filtrosAvancados.tipoLogradouro.nome.capitalize()}
              />
            )}
            {filtrosAvancados?.logradouro && (
              <BuscaAtivaFilterItem label='Logradouro:' value={filtrosAvancados.logradouro.trim().capitalize()} />
            )}
            {filtrosAvancados?.bairro && (
              <BuscaAtivaFilterItem label='Bairro:' value={filtrosAvancados.bairro.trim().capitalize()} />
            )}
          </div>
        </Cell>
        {isToggleEnabled && (
          <Cell size={2} style={classes.littleCell}>
            <Button size='small' skin='ghost' onClick={handleClick} style={classes.button}>
              <Text style={classes.buttonText}>{isExpanded ? 'Ocultar filtros' : 'Exibir filtros'}</Text>
              <Icon icon={iconAngleState} size={1} fill='info' />
            </Button>
          </Cell>
        )}
        <Cell size={12} style={classes.equipeCell}>
          <LinkVerEquipesVinculadasView equipe={equipe} />
        </Cell>
      </Grid>
    </VFlow>
  )
}

const createStyles = (expandedLineClamp: number) => ({
  bigCell: css`
    display: -webkit-box;
    -webkit-line-clamp: ${expandedLineClamp};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  littleCell: css`
    display: flex;
    position: relative;
    margin-bottom: auto;
  `,
  button: css`
    margin-left: auto;
  `,
  buttonText: css`
    padding-right: 0.5rem;
  `,
  equipeCell: css`
    padding: 0.5rem 0 0.5rem 0.25rem;
  `,
  boldText: css`
    font-weight: bold;
  `,
})
