export const BOTAO_TESTAR_TOOLTIP_TEXT = 'Verificar se o diretório de destino dos arquivos existe e está acessível.'

export const DESABILITAR_CONFIRM_BODY_TEXT =
  'Ao desabilitar, os profissionais de saúde não poderão mais anexar arquivos ao prontuário de um cidadão, mas continuarão tendo acesso aos arquivos já anexados.'

export const VALIDATION_ERROR_MSG = 'O diretório informado não existe ou não está acessível.'

export const HABILITAR_CONFIRM_BODY_TEXT =
  'Ao habilitar, os profissionais de saúde poderão anexar arquivos ao prontuário de um cidadão e os arquivos serão armazenados no diretório informado.'

export interface ConfiguracaoAnexoArquivosModel {
  diretorio: string
}
