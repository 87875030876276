/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormControl, HFlow, Tooltip, VFlow } from 'bold-ui'
import { ErrorField, RadioField } from 'components/form'
import { SituacaoProblema } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'

import { ProblemasCondicoesFormProps } from '../ProblemasCondicoesForm'
import {
  getdSituacaoLatenteFieldTooltipAndIsDisabled,
  getSituacaoAtivoFieldTooltipAndIsDisabled,
  getSituacaoResolvidoFieldTooltipAndIsDisabled,
} from '../utils/messages'
import { isSituacaoProblemaResolvidoDesabilitadaPreNatal } from '../utils/verifications-problemasCondicoes'

interface SituacaoProblemaFieldProps
  extends Pick<ProblemasCondicoesFormProps, 'isAntecedentes' | 'isLPC' | 'tipoPreNatal' | 'edicao'> {
  name: MetaPath<SituacaoProblema>
  isCiapW78: boolean
  isProblemaAltoRisco: boolean
  isSituacaoProblemaResolvido: boolean
  isEvolucao: boolean
}

export function SituacaoProblemaField(props: SituacaoProblemaFieldProps) {
  const {
    name,
    isCiapW78,
    isProblemaAltoRisco,
    isEvolucao,
    isSituacaoProblemaResolvido,
    isAntecedentes = false,
    edicao = false,
    isLPC = false,
    tipoPreNatal,
  } = props

  const isProblemaAltoRiscoEmEdicaoNaAvaliacao = isProblemaAltoRisco && edicao && !isLPC
  const isProblemaAltoRiscoResolvidoEmEdicaoNaAvaliacao =
    isProblemaAltoRiscoEmEdicaoNaAvaliacao && isSituacaoProblemaResolvido

  const { tooltip: ativoTooltip, isDisabled: isAtivoDisabled } = getSituacaoAtivoFieldTooltipAndIsDisabled(
    isAntecedentes,
    isProblemaAltoRiscoResolvidoEmEdicaoNaAvaliacao
  )

  const { tooltip: latenteTooltip, isDisabled: isLatenteDisabled } = getdSituacaoLatenteFieldTooltipAndIsDisabled(
    isAntecedentes,
    isCiapW78,
    isProblemaAltoRiscoResolvidoEmEdicaoNaAvaliacao
  )

  const { tooltip: resolvidoTooltip, isDisabled: isResolvidoDisabled } = getSituacaoResolvidoFieldTooltipAndIsDisabled(
    isAntecedentes,
    isSituacaoProblemaResolvidoDesabilitadaPreNatal(isCiapW78, isEvolucao, edicao, isLPC, tipoPreNatal),
    isProblemaAltoRiscoEmEdicaoNaAvaliacao && !isSituacaoProblemaResolvido
  )

  return (
    <FormControl label='Situação' required={!isAntecedentes}>
      <VFlow vSpacing={0.5}>
        <HFlow
          style={css`
            margin-left: -0.5rem;
            padding-top: 0.5rem;
          `}
        >
          <Tooltip text={ativoTooltip}>
            <div>
              <RadioField
                name={name}
                disabled={isAtivoDisabled}
                label='Ativo'
                value={SituacaoProblema.ATIVO}
                dataTestId='SituacaoProblemaField.ativo'
              />
            </div>
          </Tooltip>
          <Tooltip text={latenteTooltip}>
            <div>
              <RadioField
                name={name}
                disabled={isLatenteDisabled}
                label='Latente'
                value={SituacaoProblema.LATENTE}
                dataTestId='SituacaoProblemaField.latente'
              />
            </div>
          </Tooltip>
          <Tooltip text={resolvidoTooltip}>
            <div>
              <RadioField
                name={name}
                disabled={isResolvidoDisabled}
                label='Resolvido'
                value={SituacaoProblema.RESOLVIDO}
              />
            </div>
          </Tooltip>
        </HFlow>
        <ErrorField name={name} />
      </VFlow>
    </FormControl>
  )
}
