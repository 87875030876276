/** @jsx jsx */
import { useApolloClient } from '@apollo/react-hooks'
import { css, jsx } from '@emotion/core'
import ApolloClient from 'apollo-client'
import { Alert, Button, HeadingSection, HFlow, Paper, Theme, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { DateTime } from 'components/date'
import { useErrorHandler } from 'components/error'
import { CheckboxField, Form, FormRenderProps, FormValueSpy } from 'components/form'
import { AccordionField } from 'components/form/field/AccordionField'
import { PageContent } from 'components/layout/PageContent'
import { confirm } from 'components/modals/confirm'
import { usePrinter } from 'components/print'
import theme from 'config/theme'
import { useFlags } from 'config/useFlagsContext'
import { Decorator } from 'final-form'
import {
  useCancelarAtendimentoMutation,
  useEscutaInicialSaveMutation,
  useSalvarEscutaInicialParaAprovacaoMutation,
} from 'graphql/hooks.generated'
import { Lotacao, Procedimento, TipoEstabelecimentoEnum, TipoProcedimentoEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useServerTime } from 'hooks/useServerTime'
import { isEmpty } from 'lodash'
import { Dispatch, Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { AccordionItem } from 'react-accessible-accordion'
import { FormSpy } from 'react-final-form'
import { useHistory } from 'react-router'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { useVerificarAgendamentosConflitantes } from 'view/agenda/hooks/useVerificarAgendamentosConflitantes'

import { MedicoesPanel } from '../components/MedicoesPanel/MedicoesPanel'
import { PeriodoGestacaoModel } from '../detail/components/modals/types/PeriodoGestacaoModel'
import { convertAgendarConsultasToAgendamentosConflitantesInput } from '../detail/soap/finalizacao'
import { AgendarConsultaPanel } from '../detail/soap/finalizacao/components/AgendarConsultaPanel'
import { DesfechoAtendimentoPanel } from '../detail/soap/finalizacao/components/DesfechoAtendimentoPanel'
import { EncaminhamentoForm } from '../detail/soap/finalizacao/components/EncaminhamentoForm'
import { ProcedimentoSigtapField } from '../detail/soap/finalizacao/components/ProcedimentoSigtapField'
import { DeclaracaoComparecimentoButton } from '../detail/soap/plano/atestados/DeclaracaoComparecimentoBox'
import { SoapSection } from '../detail/soap/SoapSection'
import { CidadaoAtendimento } from '../types/CidadaoAtendimento'
import { createEscutaInicialCalculator } from './calculator-escutaInicial'
import { ClassificacaoRiscoButtonGroup } from './classificacao-risco/ClassificacaoRiscoButtonGroup'
import { convertEscutaInicialModelToInput } from './converter-escutaInicial'
import { downloadEscutaInicial } from './impressao/DownloadEscutaInicial'
import { ImpressaoEscutaInicial } from './impressao/ImpressaoEscutaInicial'
import { escutaMessages, preAtendimentoMessages } from './messages'
import { EscutaInicialState, name } from './model'
import { MotivoConsultaForm } from './motivo-consulta/MotivoConsultaForm'
import { ProcedimentoAdministrativoEscutaInicialField } from './procedimento-administrativo/ProcedimentoAdministrativoEscutaInicialField'
import { escutaInicialValidator } from './validator-escutaInicial'

interface EscutaInicialMainViewProps {
  atendimentoId: ID
  cidadao: CidadaoAtendimento
  cacheState: EscutaInicialState
  procedimentosAutomaticos: Array<Procedimento>
  isPreAtendimento: boolean
  updateCache: Dispatch<EscutaInicialState>
  clearCache(updateState?: boolean): void
  gestacoes: PeriodoGestacaoModel[]
  dataAtendimento: Date
  isRevisaoAtendimento: boolean
}

interface EscutaInicialFormProps extends Omit<EscutaInicialMainViewProps, 'cacheState'> {
  initialValues: EscutaInicialState
}

const createDecorators = (
  procedimentosAutomaticos: Array<Procedimento>,
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  apollo: ApolloClient<object>
): Decorator[] => [createEscutaInicialCalculator(procedimentosAutomaticos, tipoEstabelecimento, apollo)]

export function EscutaInicialMainView(props: EscutaInicialMainViewProps) {
  const { cacheState, ...rest } = props
  const [initialValues] = useState(cacheState) // usado pra setar os valores iniciais do Form apenas na primeira vez que renderizar
  return <EscutaInicialForm {...rest} initialValues={initialValues} />
}

const EscutaInicialForm = memo((props: EscutaInicialFormProps) => {
  const {
    atendimentoId,
    cidadao,
    initialValues,
    procedimentosAutomaticos,
    isPreAtendimento,
    gestacoes,
    updateCache,
    clearCache,
    dataAtendimento,
    isRevisaoAtendimento,
  } = props
  const {
    isEstagio,
    data: { acesso },
  } = useSession()
  const tipoEstabelecimento = (acesso as Lotacao).unidadeSaude.tipoEstabelecimento
  const localStyles = createStyles(theme)
  const apollo = useApolloClient()
  const [save] = useEscutaInicialSaveMutation()
  const [cancelar, { loading: isLoadingCancelar }] = useCancelarAtendimentoMutation()
  const [salvarParaAprovacao] = useSalvarEscutaInicialParaAprovacaoMutation()
  const { verificarAgendamentosConflitantes } = useVerificarAgendamentosConflitantes()
  const history = useHistory()
  const alert = useAlert()
  const handleRejection = useErrorHandler()
  const validators = useMemo(() => escutaInicialValidator, [])
  const decorator = useMemo(() => createDecorators(procedimentosAutomaticos, tipoEstabelecimento, apollo), [
    apollo,
    procedimentosAutomaticos,
    tipoEstabelecimento,
  ])
  const messages = !isPreAtendimento ? escutaMessages : preAtendimentoMessages
  const { getServerTimeNow } = useServerTime()
  const { printPDF } = usePrinter()
  const { IMPRESSAO_ESCUTA_INICIAL_ENABLED } = useFlags()

  const [formInitialized, setFormInitialized] = useState(false)
  useEffect(() => {
    setFormInitialized(true)
  }, [])

  const handleFormChange = useCallback(
    (values: EscutaInicialState) => {
      /* Avoids the error of updating a component while rendering a different component. */
      if (formInitialized) {
        values = { ...values, lastSaved: new Date() }
        updateCache(values)
      }
    },
    [formInitialized, updateCache]
  )
  const { analytics } = useFirebase()

  const salvar = useCallback(
    async (values: EscutaInicialState) => {
      const confirmouHorariosConflitantes = await verificarAgendamentosConflitantes(
        cidadao.id,
        convertAgendarConsultasToAgendamentosConflitantesInput(values.agendamentoConsulta),
        cidadao.nomeSocial ?? cidadao.nome
      )

      if (!confirmouHorariosConflitantes) return

      const input = convertEscutaInicialModelToInput(atendimentoId, values, isPreAtendimento)

      if (isEstagio) {
        return salvarParaAprovacao({
          variables: {
            input: {
              escutaInicialInput: input,
              rascunho: JSON.stringify(values),
            },
          },
        }).then((response) => {
          if (response.data.salvarEscutaInicialParaAprovacao) {
            alert(
              'success',
              `${isPreAtendimento ? 'Pré-atendimento enviado' : 'Escuta inicial enviada'} para aprovação com sucesso.`
            )
            clearCache(false)
            history.push('/lista-atendimento')
          }
        })
      } else {
        return save({
          variables: {
            input: input,
          },
        })
          .then((response) => {
            if (values.imprimirEscutaInicial) {
              if (IMPRESSAO_ESCUTA_INICIAL_ENABLED) {
                downloadEscutaInicial({ atendimentoProfissionalId: response.data.salvarEscutaInicial.id })
              } else {
                const docBody = ImpressaoEscutaInicial({
                  isPreAtendimento,
                  medicoes: values.medicoes,
                  motivoConsulta: values.motivoConsulta,
                  classificacaoRisco: values.classificacaoRisco,
                  procedimentos: values.procedimentos,
                  cidadao,
                  dataAtendimento,
                })

                printPDF(
                  {
                    docBody,
                    documentTitle: isPreAtendimento ? 'Pré-atendimento' : 'EscutaInicial',
                  },
                  handleRejection
                )
              }
            }

            alert('success', messages.successAlert)
            analytics.logEvent('finalizar_atendimento', { Tipo_de_atendimento: 'Escuta inicial/Pré-atendimento' })
            clearCache(false)
            history.push('/lista-atendimento')
          })
          .catch(handleRejection)
      }
    },
    [
      verificarAgendamentosConflitantes,
      cidadao,
      atendimentoId,
      isPreAtendimento,
      isEstagio,
      salvarParaAprovacao,
      alert,
      clearCache,
      history,
      save,
      handleRejection,
      IMPRESSAO_ESCUTA_INICIAL_ENABLED,
      messages.successAlert,
      analytics,
      dataAtendimento,
      printPDF,
    ]
  )

  const handleFinalizar = (values: EscutaInicialState) => {
    if (isEmpty(values.procedimentos)) {
      return new Promise((resolve) =>
        confirm({
          title: messages.procedimentosClinicos,
          confirmLabel: 'Sim',
          cancelLabel: 'Não',
          onConfirm: () => resolve(salvar(values)),
          onCancel: () => resolve(false),
          onClose: () => resolve(false),
        })()
      )
    } else {
      return salvar(values)
    }
  }

  const handleCancelar = useCallback(
    () =>
      confirm({
        title: messages.cancelTitle,
        body: 'As alterações realizadas serão perdidas.',
        confirmLabel: 'Sim',
        cancelLabel: 'Não',
        onConfirm: () => {
          cancelar({ variables: { atendimentoId } })
            .then(() => {
              clearCache(false)
              history.push('/lista-atendimento')
              analytics.logEvent('cancelar_atendimento', { tipo_de_atendimento: 'Escuta inicial/Pré-atendimento' })
            })
            .catch(handleRejection)
        },
      })(),
    [analytics, atendimentoId, cancelar, clearCache, handleRejection, history, messages.cancelTitle]
  )

  const renderForm = (formProps: FormRenderProps<EscutaInicialState>) => {
    return (
      <Fragment>
        <PageContent fluid type='filled'>
          <FormValueSpy<EscutaInicialState> onChange={handleFormChange} />
          <VFlow>
            <MotivoConsultaForm name={name.motivoConsulta} cidadao={cidadao} />
            <MedicoesPanel
              name={name.medicoes}
              dataAtendimento={dataAtendimento}
              cidadao={cidadao}
              gestacoes={gestacoes}
            />
            <HeadingSection level={3} title='Intervenções e/ou procedimentos clínicos realizados' vSpace={0}>
              <ProcedimentoSigtapField
                name={name.procedimentos}
                cidadao={cidadao}
                label='SIGTAP'
                tipoProcedimento={TipoProcedimentoEnum.CLINICO}
                dataAtendimento={dateAsYyyyMmDd(dataAtendimento)}
              />
            </HeadingSection>
            {!isPreAtendimento && <ClassificacaoRiscoButtonGroup name={name.classificacaoRisco} />}
            <AccordionField allowZeroExpanded name={name.openedAccordions}>
              <AccordionItem uuid='D'>
                <SoapSection
                  initialIcon='checkCircleFilled'
                  title={`Finalização ${isPreAtendimento ? 'do pré-atendimento' : 'da escuta inicial'}`}
                  style={localStyles.desfecho}
                >
                  <VFlow vSpacing={1}>
                    {!isPreAtendimento ? (
                      <VFlow vSpacing={1}>
                        <DeclaracaoComparecimentoButton atendimentoId={atendimentoId} />
                        <ProcedimentoAdministrativoEscutaInicialField
                          name={name.procedimentosAdministrativos}
                          label='Procedimentos administrativos (SIGTAP)'
                        />
                        <DesfechoAtendimentoPanel
                          name={name.desfecho}
                          labelManterCidadaoLista='Adicionar cidadão na lista de atendimentos'
                          labelPanel='Desfecho da escuta inicial'
                          atendimentoId={atendimentoId}
                          cidadaoId={cidadao?.id}
                        />
                        <AgendarConsultaPanel name={name.agendamentoConsulta} />
                      </VFlow>
                    ) : (
                      <HeadingSection level={3} vSpace={8} title='Manter cidadão na lista de atendimentos'>
                        <Paper style={localStyles.dadosAtendimento}>
                          <EncaminhamentoForm name={name.desfecho.atendimento} />
                        </Paper>
                      </HeadingSection>
                    )}
                  </VFlow>
                </SoapSection>
              </AccordionItem>
            </AccordionField>
          </VFlow>
        </PageContent>
        <PageContent fluid type='filled' style={localStyles.actions}>
          <HFlow justifyContent='space-between'>
            <FormSpy<EscutaInicialState> subscription={{ dirty: true, active: true, values: true }}>
              {(props) => (
                <Alert inline type='success' styles={{ wrapper: localStyles.draftAlertWrapper }}>
                  Rascunho salvo automaticamente às <DateTime format='HH:mm' value={props.values.lastSaved} />
                </Alert>
              )}
            </FormSpy>
            <HFlow justifyContent='flex-end'>
              {!isEstagio && (
                <CheckboxField
                  name={name.imprimirEscutaInicial}
                  label={`Imprimir ${isPreAtendimento ? 'pré-atendimento' : 'escuta inicial'} ao finalizar`}
                  style={localStyles.print}
                />
              )}
              {/* TODO (@RNG): Rever botão na issue #12391 */}
              <Button kind='normal' onClick={handleCancelar} loading={isLoadingCancelar} disabled={isLoadingCancelar}>
                {messages.cancelButton}
              </Button>
              <Button kind='primary' onClick={formProps.handleSubmit}>
                {isEstagio
                  ? 'Enviar para revisão'
                  : isRevisaoAtendimento
                  ? messages.aprovarButton
                  : messages.finalizarButton}
              </Button>
            </HFlow>
          </HFlow>
        </PageContent>
      </Fragment>
    )
  }

  return (
    <Form<EscutaInicialState>
      render={renderForm}
      onSubmit={handleFinalizar}
      validate={validators(isPreAtendimento, getServerTimeNow)}
      validateOnBlur
      initialValues={initialValues}
      decorators={decorator}
    />
  )
})

const createStyles = (theme: Theme) => ({
  actions: css`
    border-top: 1px solid ${theme.pallete.divider};
    height: 6.125rem;
  `,

  draftAlertWrapper: css`
    background-color: transparent;
    border-width: 0;
  `,

  dadosAtendimento: css`
    padding: 1rem;
    border-color: ${theme.pallete.gray.c60};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `,
  desfecho: css`
    margin: 0;
  `,
  print: css`
    border: 0.063rem solid ${theme.pallete.gray.c80};
    border-radius: 0.125rem;
    padding: 0.75rem 1rem;
  `,
})
