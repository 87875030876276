import createDecorator from 'final-form-calculate'
import { FaixaEtariaEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'
import { isEmpty } from 'util/validation/Util'

import { AcompanhamentoCondicaoSaudeFormModel } from './model-acompanhamentoCondicaoSaude'

const meta = metaPath<AcompanhamentoCondicaoSaudeFormModel>()

export const calculator = createDecorator(
  {
    field: [meta.grupoCondicao.absolutePath(), meta.problemasCondicoes.absolutePath()],
    updates: {
      [meta.ativoListaProblema.absolutePath()]: (
        value: [],
        allValues: AcompanhamentoCondicaoSaudeFormModel,
        prevValues: AcompanhamentoCondicaoSaudeFormModel
      ) => {
        const previousCondicoesWereEmpty = isEmpty(prevValues.grupoCondicao) && isEmpty(prevValues.problemasCondicoes)
        const currentCondicoesAreEmpty = isEmpty(allValues.grupoCondicao) && isEmpty(allValues.problemasCondicoes)

        if (currentCondicoesAreEmpty) return false
        if (isObjectDeepEmpty(prevValues)) return allValues.ativoListaProblema
        if (previousCondicoesWereEmpty && !currentCondicoesAreEmpty) return true

        return allValues.ativoListaProblema
      },
    },
  },
  {
    field: meta.isBotaoIvcfAtivo.absolutePath(),
    updates: {
      [meta.tipoFaixaEtaria.absolutePath()]: (_: [], allValues: AcompanhamentoCondicaoSaudeFormModel) => {
        return allValues.isBotaoIvcfAtivo && allValues.tipoFaixaEtaria !== FaixaEtariaEnum.OUTRA
          ? FaixaEtariaEnum.IDOSO
          : allValues.tipoFaixaEtaria
      },
    },
  }
)
