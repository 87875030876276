/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors, Heading, Theme, useTheme, VFlow } from 'bold-ui'
import { TextAreaField } from 'components/form'
import { useHasProblemasGestacaoQuery, useHasPuericulturaQuery } from 'graphql/hooks.generated'
import { SexoEnum } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { isCidadaoPuericultura } from 'util/isCidadaoPuericultura'
import { MetaPath } from 'util/metaPath'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { Problema } from '../aside/types/ProblemaModel'
import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import { CIAPS_GESTACAO_CODIGOS, CIDS_GESTACAO_CODIGOS } from '../pre-natal/model-preNatal'
import { AcompanhamentoPuericulturaPanel } from './AcompanhamentoPuericulturaPanel'
import { AntecedentesProblemasResolvidosTable } from './AntecedentesProblemasResolvidosTable'
import { HospitalarAntecedentesTable } from './HospitalarAntecedentesTable'
import { InformacoesObstetricasPanel } from './InformacoesObstetricasPanel'
import { PessoalAntecedentesModel } from './model'
import { PuericulturaPanel } from './PuericulturaPanel'

export interface PessoalAntecedentesViewProps {
  name: MetaPath<PessoalAntecedentesModel>
  cidadao: CidadaoAtendimento
  prontuarioId: ID
  dataAtualizacaoObstetricos: Instant
  dataAtualizacaoPuericultura: Instant
  problemasAvaliados: ProblemaCondicaoModel[]
  problemasResolvidos: Problema[]
}

export function PessoalAntecedentesView(props: PessoalAntecedentesViewProps) {
  const {
    name,
    cidadao,
    prontuarioId,
    dataAtualizacaoPuericultura,
    dataAtualizacaoObstetricos,
    problemasAvaliados,
    problemasResolvidos,
  } = props

  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme), [theme])

  const {
    data: { hasPuericultura },
  } = useHasPuericulturaQuery({ variables: { prontuarioId } })

  const { data: dataProblemasGestacao } = useHasProblemasGestacaoQuery({
    variables: { id: prontuarioId },
  })

  const hasProblemasGestacaoAvaliados = useMemo(() => {
    problemasAvaliados?.some((problema) => {
      return CIAPS_GESTACAO_CODIGOS.has(problema.ciap?.codigo) || CIDS_GESTACAO_CODIGOS.has(problema.cid?.codigo)
    })
  }, [problemasAvaliados])

  const showPuericultura = useMemo(() => {
    if (hasPuericultura) {
      if (isCidadaoPuericultura(cidadao.idadeEmAnos)) {
        return 'antesDezenoveAnos'
      } else {
        return 'aposDezenoveAnos'
      }
    } else {
      return false
    }
  }, [hasPuericultura, cidadao.idadeEmAnos])

  const showInformacoesObstetricas = useMemo(() => {
    return (
      (cidadao.sexo === SexoEnum.FEMININO ||
        cidadao.sexo === SexoEnum.INDETERMINADO ||
        !isEmpty(cidadao.identidadeGeneroDbEnum)) &&
      (cidadao.idadeEmAnos >= 9 ||
        (cidadao.idadeEmAnos >= 5 && (dataProblemasGestacao.hasProblemasGestacao || hasProblemasGestacaoAvaliados)))
    )
  }, [
    cidadao.sexo,
    cidadao.idadeEmAnos,
    cidadao.identidadeGeneroDbEnum,
    dataProblemasGestacao.hasProblemasGestacao,
    hasProblemasGestacaoAvaliados,
  ])

  return (
    <VFlow vSpacing={1} style={classes.container}>
      <TextAreaField
        name={name.texto}
        maxLength={2000}
        placeholder='Insira as demais informações relacionadas aos antecedentes do cidadão.'
        style={classes.observacoes}
      />

      <VFlow vSpacing={0.2}>
        {(showPuericultura || showInformacoesObstetricas) && (
          <Heading level={3}>Antecedentes sobre Parto e Nascimento</Heading>
        )}
        {showPuericultura === 'antesDezenoveAnos' && (
          <PuericulturaPanel name={name.puericultura} dataAtualizacao={dataAtualizacaoPuericultura} />
        )}
        {showPuericultura === 'aposDezenoveAnos' && <AcompanhamentoPuericulturaPanel prontuarioId={prontuarioId} />}
        {showInformacoesObstetricas && (
          <InformacoesObstetricasPanel
            name={name.informacoesObstetricas}
            dataAtualizacao={dataAtualizacaoObstetricos}
          />
        )}
      </VFlow>

      <AntecedentesProblemasResolvidosTable problemasResolvidos={problemasResolvidos} />
      <HospitalarAntecedentesTable name={name.cirurgiasInternacoes} dataNascimentoCidadao={cidadao.dataNascimento} />
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    margin-top: 0.5rem;
    padding: 1rem;
    border: 1px ${colors.orange.c60} solid;
    border-radius: ${theme.radius.input}px;
  `,
  observacoes: css`
    resize: vertical;
    height: 5rem;
  `,
})
