import { GrupoExame, Procedimento, SexoEnum } from 'graphql/types.generated'
import { isEmpty, isEqual, isNil } from 'lodash'

export const orderAndFilterGruposExames = (
  gruposExames: GrupoExame[],
  sexoCidadao: SexoEnum,
  idadeNoAtendimento: number
): GrupoExame[] => {
  if (!gruposExames) return []

  return gruposExames
    .filter(
      (grupoExame) => idadeNoAtendimento >= grupoExame.idadeMinima && idadeNoAtendimento <= grupoExame.idadeMaxima
    )
    .map((grupoExame) => ({
      ...grupoExame,
      procedimentos: grupoExame.procedimentos
        .filter(getValidProcedimentosGrupoExames(idadeNoAtendimento, sexoCidadao))
        .sort((a, b) => a.descricao.localeCompare(b.descricao)),
    }))
    .filter((grupoExame) => !isEmpty(grupoExame.procedimentos))
    .sort((a, b) => a.nome.localeCompare(b.nome))
}

const getValidProcedimentosGrupoExames = (idadeNoAtendimento: number, sexoCidadao: SexoEnum) => (
  procedimento: Procedimento
): boolean =>
  idadeNoAtendimento >= procedimento.idadeMinima &&
  idadeNoAtendimento <= procedimento.idadeMaxima &&
  (isNil(sexoCidadao) ||
    isEqual(SexoEnum.INDETERMINADO, sexoCidadao) ||
    isEqual(SexoEnum.AMBOS, procedimento.sexo) ||
    isEqual(sexoCidadao, procedimento.sexo))
