import { Omit, TextArea as BoldTextArea, TextAreaProps as BoldTextAreaProps } from 'bold-ui'
import React from 'react'
import { CypressProps } from 'types/cypress'

import { UseFieldProps, usePecField } from '../hooks/useField'
import { getFieldError } from '../util'

export type TextAreaFieldProps = UseFieldProps<any> & Omit<BoldTextAreaProps, 'name'> & CypressProps

export function TextAreaField({ dataTestId, ...restProps }: TextAreaFieldProps) {
  const { input, meta, ...rest } = usePecField(restProps)

  return <BoldTextArea error={getFieldError(meta)} data-testid={dataTestId} {...input} {...rest} />
}
