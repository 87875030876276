import { Checkbox, CheckboxProps, Omit } from 'bold-ui'
import React from 'react'
import { CypressProps } from 'types/cypress'

import { UseFieldProps, usePecField } from '../hooks/useField'

export type CheckboxFieldProps = UseFieldProps<any> & Omit<CheckboxProps, 'name'> & CypressProps

export function CheckboxField(props: CheckboxFieldProps) {
  const { input, meta, dataTestId, ...rest } = usePecField({ type: 'checkbox', ...props })

  return <Checkbox data-testid={dataTestId} {...input} {...rest} />
}
