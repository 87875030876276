import { Cell, Grid, HeadingSection, HFlow, VFlow } from 'bold-ui'
import { FormRenderProps, RadioField } from 'components/form'
import { AgeRangeField } from 'components/form/field/AgeRangeField'
import { css } from 'emotion'
import { FaixaEtariaEnum } from 'graphql/types.generated'
import { AgeRangeType } from 'graphql/types.generated'
import React from 'react'
import { MetaRoot } from 'util/metaPath'

import { AcompanhamentoFilterModel, faixaEtaria } from '../models/model-acompanhamentoFilter'

interface FaixaEtariaFilterGroup<T> {
  change: FormRenderProps<AcompanhamentoFilterModel>['form']['change']
  meta: MetaRoot<T>
  faixaEtariaFilter: FormRenderProps<AcompanhamentoFilterModel>['values']['tipoFaixaEtaria']
  isBotaoIvcfHabilitado?: boolean
}

export function FaixaEtariaFilterGroup<T extends AcompanhamentoFilterModel>(props: FaixaEtariaFilterGroup<T>) {
  const { faixaEtariaFilter, change, meta, isBotaoIvcfHabilitado } = props

  const handleAgeRangeChange = () => change(meta.faixaEtariaRange.alias, undefined)

  return (
    <Grid>
      <Cell size={12} style={styles.titleFaixaEtaria}>
        <HeadingSection title='Faixa etária' level={5} />
      </Cell>
      <Cell size={4} style={styles.columnFaixaEtaria}>
        <VFlow vSpacing={0.5}>
          <RadioField
            name={meta.tipoFaixaEtaria}
            value={FaixaEtariaEnum.TODAS_FAIXAS}
            label={faixaEtaria[FaixaEtariaEnum.TODAS_FAIXAS]}
            onChange={handleAgeRangeChange}
            disabled={isBotaoIvcfHabilitado}
          />
          <RadioField
            name={meta.tipoFaixaEtaria}
            value={FaixaEtariaEnum.CRIANCA}
            label={faixaEtaria[FaixaEtariaEnum.CRIANCA]}
            onChange={handleAgeRangeChange}
            disabled={isBotaoIvcfHabilitado}
          />
          <RadioField
            name={meta.tipoFaixaEtaria}
            value={FaixaEtariaEnum.ADOLESCENTE}
            label={faixaEtaria[FaixaEtariaEnum.ADOLESCENTE]}
            onChange={handleAgeRangeChange}
            disabled={isBotaoIvcfHabilitado}
          />
        </VFlow>
      </Cell>
      <Cell size={8} style={styles.columnFaixaEtaria}>
        <VFlow vSpacing={0.5}>
          <RadioField
            name={meta.tipoFaixaEtaria}
            value={FaixaEtariaEnum.ADULTO}
            label={faixaEtaria[FaixaEtariaEnum.ADULTO]}
            onChange={handleAgeRangeChange}
            disabled={isBotaoIvcfHabilitado}
          />
          <RadioField
            name={meta.tipoFaixaEtaria}
            value={FaixaEtariaEnum.IDOSO}
            label={faixaEtaria[FaixaEtariaEnum.IDOSO]}
            onChange={handleAgeRangeChange}
          />
          <HFlow style={styles.rowOutra}>
            <RadioField
              name={meta.tipoFaixaEtaria}
              value={FaixaEtariaEnum.OUTRA}
              label={faixaEtaria[FaixaEtariaEnum.OUTRA]}
              onChange={handleAgeRangeChange}
            />
            <AgeRangeField
              name={meta.faixaEtariaRange}
              disabled={faixaEtariaFilter !== FaixaEtariaEnum.OUTRA}
              rangeTypeOptions={
                isBotaoIvcfHabilitado ? [AgeRangeType.ANOS] : [AgeRangeType.ANOS, AgeRangeType.MESES, AgeRangeType.DIAS]
              }
              firstInputPlaceholder={isBotaoIvcfHabilitado ? 'Idade mínima (60)' : 'Idade mínima'}
            />
          </HFlow>
        </VFlow>
      </Cell>
    </Grid>
  )
}

const styles = {
  titleFaixaEtaria: css`
    padding-bottom: 0;
  `,
  columnFaixaEtaria: css`
    padding-top: 0;
  `,
  rowOutra: css`
    max-width: 28rem;
  `,
}
