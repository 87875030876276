import { booleanToSimNaoEnum } from 'components/form'
import { formatNumber } from 'util/number'
import { ParticipanteModel } from 'view/atividade-coletiva/model-atividadeColetiva'

import { ParticipanteEditableTableModel } from './ParticipanteEditableTable'

const getNome = (model: ParticipanteModel) => (model.cidadao ?? model.cidadaoParticipante).nome
const sortByNome = (a: ParticipanteModel, b: ParticipanteModel) => getNome(a).localeCompare(getNome(b))

const mapItems = (model: ParticipanteModel, index: number): ParticipanteEditableTableModel => ({
  _id: index,
  isCidadaoParticipanteFormOpen: !!model.cidadaoParticipante,
  cidadao: model.cidadao,
  cidadaoParticipante: model.cidadaoParticipante
    ? {
        nome: model.cidadaoParticipante.nome,
        cpf: model.cidadaoParticipante.cpf,
        cns: model.cidadaoParticipante.cns,
        dataNascimento: model.cidadaoParticipante.dataNascimento,
        sexo: model.cidadaoParticipante.sexo,
      }
    : undefined,
  peso: formatNumber(model.peso),
  altura: formatNumber(model.altura, 0),
  avaliacaoAlterada: model.avaliacaoAlterada,
  pnctCessouHabitoFumar: booleanToSimNaoEnum(model.pnctCessouHabitoFumar),
  pnctAbandonouGrupo: booleanToSimNaoEnum(model.pnctAbandonouGrupo),
})

export const convertParticipantesToParticipantesEditableTableModel = (
  participantes?: ParticipanteModel[]
): ParticipanteEditableTableModel[] => participantes?.slice().sort(sortByNome).reverse().map(mapItems)
