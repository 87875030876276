import { HFlow, Tag } from 'bold-ui'
import React from 'react'
import { CypressProps } from 'types/cypress'

interface RiscoGravidezTagProps extends CypressProps {
  isAltoRisco: boolean
}

export function RiscoGravidezTag(props: RiscoGravidezTagProps) {
  const { isAltoRisco, dataTestId } = props

  return (
    <HFlow data-testid={dataTestId}>
      {isAltoRisco ? <Tag type='danger'>Alto</Tag> : <Tag type='info'>Habitual</Tag>}
    </HFlow>
  )
}
