import { Button, ButtonProps } from 'bold-ui'
import { Cancelable, throttle } from 'lodash'
import React, { useCallback } from 'react'
import { useFormState } from 'react-final-form'
import { CypressProps } from 'types/cypress'

type TrottledSubmit = ((handleSubmit: () => void) => void) & Cancelable

interface SubmitButtonProps extends ButtonProps, CypressProps {
  throttleSubmit?: boolean
  throttleDelay?: number
  handleSubmit: (event?: React.SyntheticEvent<HTMLFormElement>) => void
}

export function SubmitButton(props: SubmitButtonProps) {
  const { throttleSubmit = false, throttleDelay = 2000, type = 'submit', handleSubmit, dataTestId, ...rest } = props

  const { submitting } = useFormState({ subscription: { submitting: true } })

  const trottledSubmit: TrottledSubmit = useCallback(
    throttle((handleSubmit: () => void) => handleSubmit(), throttleDelay),
    [throttleDelay]
  )

  return (
    <Button
      kind='primary'
      type={type}
      loading={submitting}
      disabled={submitting}
      onClick={throttleSubmit ? () => trottledSubmit(handleSubmit) : handleSubmit}
      data-testid={dataTestId}
      {...rest}
    />
  )
}
