import { DateRange } from 'bold-ui'
import { isSameDay } from 'date-fns'
import { isUndefinedOrNull } from 'util/checks'

import { calculateAge } from './calculateAge'
import { dateAsDdMmYyyy } from './formatDate'

export const HOJE = 'Hoje'

export function humanizeDate(date: LocalDate | number, refDate: LocalDate | number = Date.now()) {
  const { years, months, days } = calculateAge(date, refDate)

  if (years === 0 && months === 0 && days === 0) {
    return HOJE
  } else if (years < 1 && months < 1) {
    return `${days} ${days === 1 ? 'dia' : 'dias'}`
  } else if (years < 1) {
    return `${months} ${months === 1 ? 'mês' : 'meses'}${days > 0 ? ` e ${days} ${days === 1 ? 'dia' : 'dias'}` : ''}`
  } else if (years >= 1 && months === 0) {
    if (days > 0) {
      return `${years} ${years === 1 ? 'ano' : 'anos'} e ${days} ${days === 1 ? 'dia' : 'dias'}`
    } else {
      return `${years} ${years === 1 ? 'ano' : 'anos'}`
    }
  } else {
    if (days > 0) {
      return `${years} ${years === 1 ? 'ano' : 'anos'}, ${months} ${months === 1 ? 'mês' : 'meses'} e ${days} ${
        days === 1 ? 'dia' : 'dias'
      }`
    } else {
      return `${years} ${years === 1 ? 'ano' : 'anos'} e ${months} ${months === 1 ? 'mês' : 'meses'}`
    }
  }
}

export function humanizeDateTime(date: LocalDate | number, refDate: LocalDate | number = Date.now()) {
  const { years, months, days, hours, minutes } = calculateAge(date, refDate)

  if (years === 0 && months === 0 && days === 0) {
    if (hours === 0) {
      return `${minutes} ${minutes <= 1 ? 'minuto' : 'minutos'}`
    } else {
      return `${hours} ${hours <= 1 ? 'hora' : 'horas'}`
    }
  } else {
    return humanizeDate(date, refDate)
  }
}

export function humanizeDateRange(dateRange: Partial<DateRange>, today: Date | number = Date.now()) {
  const hasStartDate = !isUndefinedOrNull(dateRange?.startDate)
  const hasEndDate = !isUndefinedOrNull(dateRange?.endDate)
  const isStartDateToday = isSameDay(dateRange?.startDate, today)
  const isEndDateToday = isSameDay(dateRange?.endDate, today)
  const startDateText = isStartDateToday ? HOJE : dateAsDdMmYyyy(dateRange?.startDate)
  const endDateText = isEndDateToday ? HOJE : dateAsDdMmYyyy(dateRange?.endDate)

  if (!hasStartDate && !hasEndDate) {
    return
  } else if (!hasStartDate && hasEndDate) {
    return `Até ${endDateText}`.capitalize()
  } else if (hasStartDate && !hasEndDate) {
    return `A partir de ${startDateText}`.capitalize()
  } else if (isStartDateToday && isEndDateToday) {
    return HOJE
  } else if (isSameDay(dateRange.startDate, dateRange.endDate)) {
    return startDateText
  } else {
    return `${startDateText} até ${endDateText}`.capitalize()
  }
}
