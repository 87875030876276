import { HFlow, Icon, Tag, Text, Tooltip, useTheme, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { HLabel } from 'components/HLabel'
import { parseISO } from 'date-fns'
import { css } from 'emotion'
import React, { useMemo } from 'react'
import { emptyArray } from 'util/array'
import { dateAsDdMmYyyy, dateAsYyyyMmDd } from 'util/date/formatDate'
import { roundedAge } from 'util/date/rounded-age'

import { getAsideCardStyles } from '../../components/ContentCardSection'
import { Problema, situacaoProblemaRecord } from '../../types/ProblemaModel'

interface ProblemasListCardProps {
  problemas: Problema[]
  readOnly: boolean
}

export const ProblemasListCard = (props: ProblemasListCardProps) => {
  const { problemas = emptyArray, readOnly } = props

  const theme = useTheme()
  const cardStyles = useMemo(() => getAsideCardStyles(), [])

  return (
    <VFlow vSpacing={0.5}>
      {problemas.map(({ id, ciap, cid10, descricaoOutro, situacao, isAvaliadoAgora, ultimaEvolucao }) => {
        const situacaoProblema = situacaoProblemaRecord[situacao]
        const dataInicio = ultimaEvolucao.dataInicio && parseISO(ultimaEvolucao.dataInicio)
        const roundedStartDate = dataInicio && roundedAge(dateAsYyyyMmDd(dataInicio))

        return (
          <HFlow hSpacing={0.5} alignItems='flex-start' key={id} data-testid='ProblemasListCard.container'>
            <Tag
              style={css`
                color: ${situacaoProblema.color};
                background: ${situacaoProblema.background};
              `}
              data-testid='ProblemasListCard.situacao'
            >
              {situacaoProblema.sigla}
            </Tag>

            <VFlow vSpacing={0}>
              <HFlow hSpacing={0.5} alignItems='flex-start' data-testid='ProblemasListCard.ciapCid'>
                <Text component='p' style={cardStyles.ellipsesText}>
                  {ciap && `${ciap.descricao} (${ciap.codigo})`}
                  {cid10 && `${separator(ciap)} ${cid10.nome} (${cid10.codigo})`}
                  {descricaoOutro && `${separator(cid10 || ciap)} ${descricaoOutro}`}
                </Text>

                {isAvaliadoAgora && (
                  <Tooltip text='Alterado agora'>
                    <Icon
                      style={css`
                        margin-top: 0.15rem;
                      `}
                      icon='clockOutline'
                      color={theme.pallete.primary.c40}
                      size={1}
                    />
                  </Tooltip>
                )}
              </HFlow>

              {readOnly && (
                <VFlow vSpacing={0}>
                  <HLabel title='Início:' dataTestId='ProblemasListCard.dataInicio'>
                    {dataInicio &&
                      `${dateAsDdMmYyyy(dataInicio)} | ${
                        roundedStartDate === 'Hoje' ? roundedStartDate : `Há ${roundedStartDate.toLowerCase()}`
                      }`}
                  </HLabel>
                  <HLabel title='Última atualização:' dataTestId='ProblemasListCard.ultimaAtualizacao'>
                    <DateTime value={ultimaEvolucao.atualizadoEm} format='DD/MM/YYYY' />
                  </HLabel>
                </VFlow>
              )}
            </VFlow>
          </HFlow>
        )
      })}
    </VFlow>
  )
}

const separator = (expression: any): string => (expression ? ' | ' : '')
