import React from 'react'

import { RadioGroupField, RadioGroupFieldProps, RadioOption } from './RadioGroupField'

export enum SimNaoEnum {
  SIM = 'SIM',
  NAO = 'NAO',
}

interface SimNaoRadioGroupFieldProps extends Omit<RadioGroupFieldProps<SimNaoEnum>, 'options'> {}

export function SimNaoRadioGroupField(props: SimNaoRadioGroupFieldProps) {
  return <RadioGroupField options={options} {...props} />
}

const options: RadioOption<SimNaoEnum>[] = [
  { value: SimNaoEnum.SIM, label: 'Sim' },
  { value: SimNaoEnum.NAO, label: 'Não' },
]

export const booleanToSimNaoEnum = (value: boolean) => {
  return value !== null && (value ? SimNaoEnum.SIM : SimNaoEnum.NAO)
}

export const simNaoEnumToBoolean: Record<SimNaoEnum, boolean> = {
  SIM: true,
  NAO: false,
}

export const simNaoEnumRecord: Record<SimNaoEnum, string> = {
  SIM: 'Sim',
  NAO: 'Não',
}
