import { Cell, Grid, HeadingSection, InfoLabel } from 'bold-ui'
import { DateTime } from 'components/date'
import { Cpf, Telefone } from 'components/label'
import { ProfissionalDetailFragment } from 'graphql/types.generated'
import React from 'react'
import { sexo } from 'types/enums'
import { capitalize } from 'util/strConversor'

export interface ProfissionalInformacoesSectionProps {
  profissional: ProfissionalDetailFragment
}

export const ProfissionalInformacoesSection = (props: ProfissionalInformacoesSectionProps) => {
  const { profissional } = props
  return (
    <HeadingSection level={2} title='Dados pessoais'>
      <Grid wrap>
        <Cell size={4}>
          <InfoLabel title='CPF'>
            <Cpf value={profissional.cpf} />
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='CNS'>{profissional.cns}</InfoLabel>
        </Cell>
        <Cell size={4} />
        <Cell size={4}>
          <InfoLabel title='Data de nascimento'>
            <DateTime format='DD/MM/YYYY' value={profissional.dataNascimento} />
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Sexo'>{sexo[profissional.sexo]}</InfoLabel>
        </Cell>
        <Cell size={4} />
        <Cell size={4}>
          <InfoLabel title='Conselho de classe'>{capitalize(profissional?.conselhoClasse?.descricao)}</InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Estado emissor'>
            {profissional.ufEmissoraConselhoClasse && profissional.ufEmissoraConselhoClasse.sigla}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Registro no conselho de classe'>
            {profissional?.numeroConselhoClasse?.toUpperCase()}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='E-mail'>{profissional.email}</InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Telefone'>
            <Telefone value={profissional.telefone} />
          </InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}
