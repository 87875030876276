import {
  Button,
  Cell,
  Grid,
  Heading,
  HFlow,
  Icon,
  InfoLabel,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
  Text,
  useTheme,
  VFlow,
} from 'bold-ui'
import { SincronizacaoCadsusResultEnum } from 'graphql/types.generated'
import qs from 'qs'
import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { ImovelUrlParams } from 'view/visualizacao-imovel/model-visualizacao-imovel'

import { ResultSyncCadsusType } from '../types/CadsusModel'
import { useNavigateToCidadaoCallbackUrl } from '../useNavigateToCidadaoCallbackUrl'
import { ErroAutenticacaoCadsusBody } from './ModalBuscaInformacoesCadsus'

interface ModalStatusSincronizacaoCadsus {
  loading: boolean
  result: ResultSyncCadsusType
  isOpen: boolean
  isEdit: boolean
  hasCns: boolean
  sincronizarCadsus(cidadaoId: string, cns: string): void
  setSincModalState(state: boolean): void
}

interface SucessoSincronizacaoBodyProps {
  result: ResultSyncCadsusType
  hasCns: boolean
}

interface ErroValidacaoCadsusBodyProps {
  result: ResultSyncCadsusType
  isEdit: boolean
}

interface ErroCadsusDesbilitadoBodyProps {
  status: SincronizacaoCadsusResultEnum
}

interface SucessoParcialSincronizacaoBodyProps {
  result: ResultSyncCadsusType
}

interface DuplicidadeCadastroSincronizacaoBodyProps {
  result: ResultSyncCadsusType
}

export interface UrlParams {
  callbackUrl: string
  callbackParams: string
  cadImovelUrlParams: ImovelUrlParams
}

export function ModalStatusSincronizacaoCadsus(props: ModalStatusSincronizacaoCadsus) {
  const history = useHistory()
  const location = useLocation()

  const urlParams: UrlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const navigateToCidadaoCallbackUrl = useNavigateToCidadaoCallbackUrl()

  const handleOnConcluir = () => {
    setTimeout(() => handleNavigate())
  }

  const handleNavigate = () => {
    sessionStorage.removeItem('cidadaoCadsus')
    const idCidadao = props.result.cidadaoLocal.id
    if (urlParams.callbackUrl) {
      switch (urlParams.callbackUrl) {
        case 'cadastro-cidadao':
          history.push(`/lista-atendimento/atendimento/${urlParams.callbackParams}/${urlParams.callbackUrl}`)
          break
        case 'lista-atendimento/atendimento':
        case 'lista-atendimento/atendimento-vacinacao':
          history.push(`/${urlParams.callbackUrl}/${urlParams.callbackParams}`)
          break
        case 'visualizar-escuta':
        case 'escuta-inicial':
          history.push(`/lista-atendimento/${urlParams.callbackUrl}/${urlParams.callbackParams}/cadastro-cidadao`)
          break
        default:
          navigateToCidadaoCallbackUrl(idCidadao)
          break
      }
    } else {
      history.push(`/cidadao/${idCidadao}`)
    }
  }

  const handleOnTentarNovamente = () => {
    props.sincronizarCadsus(props.result.cidadaoLocal.id, props.result.cidadaoLocal.cns)
  }

  const handleOnEditar = () => {
    sessionStorage.removeItem('cidadaoCadsus')
    if (props.isEdit) {
      props.setSincModalState(false)
    } else {
      history.push(`${props.result.cidadaoLocal.id}/edit${history.location.search}`)
    }
  }

  return (
    <Modal open={props.isOpen} size='small' closeOnBackdropClick={false} hasCloseIcon={false}>
      <div tabIndex={1} style={{ outline: '0px solid transparent' }}>
        {props.loading && <AguardandoSincronizacaoBody />}
        {props.result?.result === SincronizacaoCadsusResultEnum.SUCESSO && (
          <SucessoSincronizacaoBody result={props.result} hasCns={props.hasCns} />
        )}
        {props.result?.result === SincronizacaoCadsusResultEnum.ERRO && <ErroFalhaConexaoCadsus />}

        {(props.result?.result === SincronizacaoCadsusResultEnum.ERRO_AUTENTICACAO ||
          props.result?.result === SincronizacaoCadsusResultEnum.ERRO_CERTIFICADO_DESABILITADO) && (
          <ErroAutenticacaoCadsusBody />
        )}

        {(props.result?.result === SincronizacaoCadsusResultEnum.ERRO_CADSUS_DESABILITADO ||
          props.result?.result === SincronizacaoCadsusResultEnum.ERRO_SEM_CONEXAO) && (
          <ErroCadsusDesbilitadoBody status={props.result.result} />
        )}

        {(props.result?.result === SincronizacaoCadsusResultEnum.VALIDACAO_COM_CNS ||
          props.result?.result === SincronizacaoCadsusResultEnum.VALIDACAO_SEM_CNS ||
          props.result?.result === SincronizacaoCadsusResultEnum.LENTO) && (
          <ErroValidacaoCadsusBody result={props.result} isEdit={props.isEdit} />
        )}
        {(props.result?.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_CNS_INATIVOS ||
          props.result?.result === SincronizacaoCadsusResultEnum.POSSUI_PRONTUARIO ||
          props.result?.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_CPF_INATIVOS) && (
          <SucessoParcialSincronizacaoBody result={props.result} />
        )}

        {(props.result?.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_CNS_ATIVOS ||
          props.result?.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_CPF_ATIVOS) && (
          <DuplicidadeCadastroSincronizacaoBody result={props.result} />
        )}
        {/*TODO NP - Temporário até a implementação do sexo indeterminado pelo CADSUS.*/}
        {props.result?.result === SincronizacaoCadsusResultEnum.NAO_ENVIADO_SEXO_INDETERMINADO && (
          <NaoEnviadoSexoIndeterminadoBody />
        )}

        {!props.loading && (
          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              {(props.result?.result === SincronizacaoCadsusResultEnum.VALIDACAO_COM_CNS ||
                props.result?.result === SincronizacaoCadsusResultEnum.VALIDACAO_SEM_CNS) && (
                <Button onClick={handleOnEditar}>Editar</Button>
              )}
              {props.result?.result === SincronizacaoCadsusResultEnum.ERRO && (
                <Button onClick={handleOnTentarNovamente}>Tentar novamente</Button>
              )}
              <Button onClick={handleOnConcluir} kind='primary'>
                Concluir
              </Button>
            </HFlow>
          </ModalFooter>
        )}
      </div>
    </Modal>
  )
}

export function AguardandoSincronizacaoBody() {
  const theme = useTheme()
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Heading level={2}>
              <Spinner size={2} borderWidth={3} style={{ color: theme.pallete.primary.main, marginRight: 6 }} />
              Atualizando dados do cidadão
            </Heading>
          </HFlow>
          <Text>Aguarde um instante, estamos atualizando os dados do cidadão na base nacional (CADSUS)</Text>
        </VFlow>
      </ModalBody>
    </>
  )
}

function SucessoSincronizacaoBody(props: SucessoSincronizacaoBodyProps) {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='infoCircleOutline' fill='info' size={4} />
            <Heading level={2}>
              {props.hasCns ? 'Cidadão atualizado na base nacional (CADSUS).' : 'CNS gerado com sucesso'}
            </Heading>
          </HFlow>
          <HFlow>
            <InfoLabel title='CPF'>{props.result.cidadaoLocal.cpf}</InfoLabel>
            <InfoLabel title='CNS'>{props.result.cidadaoLocal.cns}</InfoLabel>
          </HFlow>
          {!!props.result?.camposAlterados?.length && (
            <Text fontWeight='bolder'>
              Os seguintes campos foram sobrescritos, por estarem mais atualizados na base nacional:
            </Text>
          )}
          <Grid wrap>
            {props.result?.camposAlterados?.map((item, index) => (
              <Cell style={{ wordWrap: 'break-word' }} size={6} key={index}>
                <InfoLabel title={item.campo}>{item.conteudo}</InfoLabel>
              </Cell>
            ))}
          </Grid>
        </VFlow>
      </ModalBody>
    </>
  )
}

function SucessoParcialSincronizacaoBody(props: SucessoParcialSincronizacaoBodyProps) {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='infoCircleOutline' fill='info' size={4} />
            <Heading level={2}>Cadastro atualizado parcialmente</Heading>
          </HFlow>
          {(props.result.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_CNS_INATIVOS ||
            props.result?.result === SincronizacaoCadsusResultEnum.POSSUI_PRONTUARIO) && (
            <Text>
              O Cidadao já possui um número higienizado na base nacional do CADSUS. Nâo foi possível substituir o CNS
              pelo definitivo pois esse cadastro já possui registros de atendimentos ou já existe outro cadastro na base
              de dados local com o mesmo CNS.
            </Text>
          )}
          {props.result.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_CPF_INATIVOS && (
            <Text>
              O Cidadão possui um número de CPF diferente na base nacional do CADSUS. Não foi possível substituir o CPF
              pois já existe um cadastro na base de dados local com o mesmo número.
            </Text>
          )}

          {!!props.result.camposAlterados?.length && (
            <Text fontWeight='bolder'>
              Os seguintes campos foram sobrescritos, por estarem mais atualizados na base nacional:
            </Text>
          )}
          <Grid wrap>
            {props.result?.camposAlterados?.map((item) => (
              <Cell size={6}>
                <InfoLabel title={item.campo}>{item.conteudo}</InfoLabel>
              </Cell>
            ))}
          </Grid>
        </VFlow>
      </ModalBody>
    </>
  )
}

function DuplicidadeCadastroSincronizacaoBody(props: DuplicidadeCadastroSincronizacaoBodyProps) {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='infoCircleOutline' fill='info' size={4} />
            <Heading level={2}>Duplicidade de cadastro</Heading>
          </HFlow>
          {props.result.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_CNS_ATIVOS && (
            <Text>
              O CNS definitivo para este cidadão no cadsus é {props.result.cidadaoCadsus.cns}. Identificamos que ele
              possui mais de um cadastro na base local. Comunique ao coordenador da UBS para que seja possível unificar
              os cadastros.
            </Text>
          )}
          {props.result.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_CPF_ATIVOS && (
            <Text>
              O CPF desse cidadão no cadsus é {props.result.cidadaoCadsus.cns}. Identificamos que ele possui mais de um
              cadastro na base local com este número. Comunique ao coordenador da UBS para que seja possível unificar os
              cadastros.
            </Text>
          )}

          {!!props.result.cidadaosDuplicados?.length && <Text fontWeight='bolder'>Cidadãos encontrados:</Text>}
          <Grid wrap>
            {props.result?.cidadaosDuplicados?.map((item) => (
              <Cell size={6}>
                <InfoLabel title='nome'>{item.nome}</InfoLabel>
                <InfoLabel title='cpf'>{item.cpf}</InfoLabel>
                <InfoLabel title='cns'>{item.cns}</InfoLabel>
              </Cell>
            ))}
          </Grid>

          {!!props.result.camposAlterados?.length && (
            <Text fontWeight='bolder'>
              Os seguintes campos foram sobrescritos, por estarem mais atualizados na base nacional:
            </Text>
          )}
          <Grid wrap>
            {props.result?.camposAlterados?.map((item) => (
              <Cell size={6}>
                <InfoLabel title={item.campo}>{item.conteudo}</InfoLabel>
              </Cell>
            ))}
          </Grid>
        </VFlow>
      </ModalBody>
    </>
  )
}

function ErroCadsusDesbilitadoBody(props: ErroCadsusDesbilitadoBodyProps) {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={4} />
            <Heading level={2}>Não foi possível enviar o cadastro ao CADSUS</Heading>
          </HFlow>
          <Text>
            O cidadão foi salvo na base local, mas não foi possível salvar o cadastro na base nacional,{' '}
            {props.status === SincronizacaoCadsusResultEnum.ERRO_SEM_CONEXAO
              ? 'pois a instalação está offline. '
              : 'pois a conexão com o serviço está desabilitada. '}
            Entre em contato com o responsável pela instalação para mais informações.
          </Text>
        </VFlow>
      </ModalBody>
    </>
  )
}

function NaoEnviadoSexoIndeterminadoBody() {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={4} />
            <Heading level={2}>Cadastro salvo apenas na base local</Heading>
          </HFlow>
          <Text>
            Nenhum atendimento ou produção em saúde será prejudicado, no entanto, a sincronização com o CADSUS ainda não
            suporta o envio de cidadãos com o sexo "Indeterminado". Mantenha os cadastro corretamente atualizados para
            garantir o acolhimento dos cidadãos.
          </Text>
          <Text>Para mais informações, contate a coordenação da sua unidade.</Text>
        </VFlow>
      </ModalBody>
    </>
  )
}

function ErroFalhaConexaoCadsus() {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={4} />
            <Heading level={2}>Não foi possível estabelecer a conexão com o CADSUS</Heading>
          </HFlow>
          <Text>Ocorreu um erro na comunicação com a base nacional (CADSUS).</Text>
        </VFlow>
      </ModalBody>
    </>
  )
}

function ErroValidacaoCadsusBody(props: ErroValidacaoCadsusBodyProps) {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={4} />
            <Heading level={2}>
              {props.isEdit ? 'O cadastro foi alterado' : 'O cadastro foi salvo'}, mas não foi possível atualizar os
              dados na base nacional (CADSUS).
            </Heading>
          </HFlow>
          <ul>
            {props.result.errors.map((erro) => (
              <li>{erro}</li>
            ))}
          </ul>
        </VFlow>
      </ModalBody>
    </>
  )
}
