/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { FilterTag, FilterTags } from 'components/filtertags'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { ButtonLink } from 'components/route'
import { Fragment } from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

interface AtividadeColetivaListingProps {
  title: string
  registerText?: string
  filter?: React.ReactNode
  filterTags?: FilterTag[]
  children?: React.ReactNode
}

export const AtividadeColetivaListing = (props: AtividadeColetivaListingProps) => {
  const { title, registerText, filter, filterTags, children } = props
  const match = useRouteMatch()

  return (
    <Fragment>
      <PageHeaderSection title={title} style={styles.header}>
        <VFlow style={styles.button}>
          <CheckPermission permission={Permissions.cds.fichaDeAtividadeColetiva.cadastrarEditarEExcluir}>
            <ButtonLink to={`${match.url}/registrar`} kind='primary' size='small'>
              {registerText}
            </ButtonLink>
          </CheckPermission>
        </VFlow>
      </PageHeaderSection>
      <PageContent type='transparent'>
        <VFlow vSpacing={0.5}>
          <VFlow vSpacing={1.5}>
            {filter}
            <FilterTags tags={filterTags} />
          </VFlow>
          <VFlow vSpacing={0.25}>{children}</VFlow>
        </VFlow>
      </PageContent>
    </Fragment>
  )
}

const styles = {
  header: css`
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  `,
  button: css`
    padding-bottom: 2rem;
  `,
}
