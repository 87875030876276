/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import { RouterLink } from 'components/route'
import { useConfiguracoes } from 'hooks/configuracoes'
import { useFirebase } from 'hooks/firebase/useFirebase'
import logo from 'images/esus.svg'

export function InitialContent({ children }) {
  const {
    configuracoes: { versao },
  } = useConfiguracoes()

  const { analytics } = useFirebase()

  const handleClickTermosDeUso = () => analytics.logEvent('acessar_termos_de_uso_sem_login')
  const handleClickLinksUteis = () => analytics.logEvent('links_uteis')

  return (
    <div css={styles.container}>
      <div css={styles.logo}>
        <img css={styles.img} src={logo} alt='Logo do e-SUS Atenção Primária' />
      </div>
      {children}
      <div css={styles.info}>
        <p>
          Sistema fornecido pelo <br />
          <Text fontWeight='bold'>Ministério da Saúde</Text>
        </p>
        <hr css={styles.separator} />
        <VFlow vSpacing={0.5}>
          {versao && <Text fontStyle='italic'>Versão {versao}</Text>}
          <RouterLink to='/termosUso' onClick={handleClickTermosDeUso}>
            Termos de uso
          </RouterLink>
          <RouterLink to='/linksUteis' onClick={handleClickLinksUteis}>
            Descubra mais sobre o e-SUS APS e o PEC
          </RouterLink>
        </VFlow>
      </div>
    </div>
  )
}

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
  `,
  logo: css`
    margin-bottom: 4rem;
    text-align: center;
  `,
  img: css`
    height: 50px;
  `,
  info: css`
    margin-top: 2.5rem;
    text-align: center;
  `,
  separator: css`
    width: 3rem;
    margin-left: auto;
    margin-right: auto;
  `,
}
