/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, ExternalStyles, HFlow, Icon, Omit, Radio, RadioProps, Theme, useTheme } from 'bold-ui'
import { useMemo } from 'react'
import { CypressProps } from 'types/cypress'

import { UseFieldProps, usePecField } from '../hooks/useField'

export interface RadioFieldProps
  extends UseFieldProps<any>,
    Omit<RadioProps, 'name' | 'value' | 'defaultValue' | 'onChange' | 'onFocus' | 'onBlur'>,
    CypressProps {
  clearable?: boolean
  wrapperStyle?: ExternalStyles
}

export function RadioField(props: RadioFieldProps) {
  const { clearable, dataTestId, wrapperStyle, ...restProps } = props
  const { input, meta, ...rest } = usePecField({ type: 'radio', ...restProps })

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  return (
    <HFlow style={css(classes.wrapper, wrapperStyle)}>
      <Radio data-testid={dataTestId} {...input} {...rest} />

      {clearable && input.checked && (
        <Button size='small' skin='ghost' style={classes.closeButton} onClick={() => input.onChange(undefined)}>
          <Icon icon='timesDefault' fill='secondary' />
        </Button>
      )}
    </HFlow>
  )
}

const styles = (theme: Theme) => ({
  closeButton: css`
    padding: 0;
    margin: -1px 0;
    &:hover {
      svg {
        fill: ${theme.pallete.status.danger.main};
      }
    }
  `,
  wrapper: css`
    ${theme.breakpoints.down('xl')} {
      grid-gap: 0.5rem;
    }
  `,
})
