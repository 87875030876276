import { Medicao } from 'graphql/types.generated'
import moment from 'moment'
import { formatNumber, parseNumber } from 'util/number'
import { isEmpty } from 'util/validation/Util'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { MedicaoPeso } from './model-medicoes'
import { MedicaoAntropometricaPreNatal } from './pre-natal/model-medicoesPreNatal'

export function getMedicoesAntropometricasPreNatal(medicoes: HistoricoMedicaoModel[]): MedicaoAntropometricaPreNatal[] {
  return medicoes
    .filter((medicao) => !!medicao.valorPeso || !!medicao.valorAltura)
    .map((medicao) => ({
      dataMedicao: medicao.dataMedicao,
      peso: medicao.valorPeso,
      altura: medicao.valorAltura,
      tipoMedicao: medicao.tipoMedicao,
    }))
}

export const calculatePeso = (
  pesoAtual: string,
  ultimaMedicaoAnterior: Medicao,
  dataInicioAtendimento: Date
): MedicaoPeso => {
  const pesoNumber = parseNumber(pesoAtual)
  const hasPesoAtual = !isNaN(pesoNumber)
  const pesoFormatado = formatNumber(pesoNumber)
  const hasUltimaMedicaoAnterior = !isEmpty(ultimaMedicaoAnterior) && Object.keys(ultimaMedicaoAnterior).length > 0

  if (hasPesoAtual) {
    return {
      dataMedicao: dataInicioAtendimento.getTime(),
      peso: pesoFormatado,
    }
  } else if (hasUltimaMedicaoAnterior) {
    return {
      dataMedicao: ultimaMedicaoAnterior.dataMedicao,
      peso: formatNumber(ultimaMedicaoAnterior.valorPeso),
    }
  } else return null
}

const format = (data) => moment(data).format('DD/MM/YY')

export function reduceMedicoesIguais<T extends Pick<HistoricoMedicaoModel, 'dataMedicao'>>(
  medicoes: ReadonlyArray<T>,
  key: keyof T
): T[] {
  const mapMedicoesUnicas = medicoes?.reduce(
    (acc, curr) => (curr[key] ? acc.set(`${format(curr.dataMedicao)}|${curr[key]}`, curr) : acc),
    new Map<string, T>()
  )

  return Array.from(mapMedicoesUnicas.values())
}
