import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import React from 'react'

export interface SexoSelectFieldProps extends Omit<SelectFieldProps<string>, 'items' | 'itemToString'> {}

export function SexoSelectField(props: SexoSelectFieldProps) {
  const itemToString = (item: SexoSelectModel) => item && descriptions[item]

  return <SelectField<SexoSelectModel> items={items} itemToString={itemToString} {...props} />
}

export type SexoSelectModel = string

const items = ['FEMININO', 'MASCULINO', 'INDETERMINADO']

const descriptions = {
  FEMININO: 'Feminino',
  MASCULINO: 'Masculino',
  INDETERMINADO: 'Indeterminado',
}
