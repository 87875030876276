import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { Form } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { useAlterarConfiguracaoAnexoArquivosMutation } from 'graphql/hooks.generated'
import React, { useMemo, useState } from 'react'
import { FormRenderProps } from 'react-final-form'

import {
  ConfiguracaoAnexoArquivosModel,
  DESABILITAR_CONFIRM_BODY_TEXT,
  HABILITAR_CONFIRM_BODY_TEXT,
} from '../model-anexoArquivos'
import { validate } from '../validator-anexoArquivos'
import { HabilitarAnexoArquivosForm } from './HabilitarAnexoArquivosForm'

interface HabilitarAnexoArquivosBoxProps {
  habilitado: boolean
  diretorio: string
}

export default function HabilitarAnexoArquivosBox(props: HabilitarAnexoArquivosBoxProps) {
  const { habilitado, diretorio } = props
  const handleRejection = useErrorHandler()
  const alert = useAlert()

  const [alterarAnexoArquivosMutation] = useAlterarConfiguracaoAnexoArquivosMutation()
  const [hasServerSideError, setHasServerSideError] = useState<boolean>(null)

  const handleSubmit = (formValues: ConfiguracaoAnexoArquivosModel) => {
    const titleText = habilitado ? 'desabilitar' : 'habilitar'
    const bodyText = habilitado ? DESABILITAR_CONFIRM_BODY_TEXT : HABILITAR_CONFIRM_BODY_TEXT
    const alertText = habilitado ? 'novos arquivos desabilitado' : ' arquivos habilitado'

    return confirm({
      title: `Deseja ${titleText} o anexo de arquivos?`,
      body: bodyText,
      onConfirm: () => {
        return alterarAnexoArquivosMutation({
          variables: {
            input: {
              habilitado: !habilitado,
              diretorio: formValues.diretorio.trim(),
            },
          },
        })
          .then(() => {
            setHasServerSideError(false)
            alert('success', `Anexo de ${alertText} com sucesso`)
          })
          .catch((err) => {
            setHasServerSideError(true)
            handleRejection(err)
          })
      },
    })()
  }

  const renderForm = (formProps: FormRenderProps<ConfiguracaoAnexoArquivosModel>) => (
    <HabilitarAnexoArquivosForm
      handleSubmit={formProps.handleSubmit}
      habilitado={habilitado}
      diretorio={diretorio}
      hasServerSideError={hasServerSideError}
      setHasServerSideError={setHasServerSideError}
    />
  )

  const initialValues = useMemo(() => ({ diretorio }), [diretorio])

  return (
    <Form<ConfiguracaoAnexoArquivosModel>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={renderForm}
    />
  )
}
