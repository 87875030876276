import { MedicoesAtendimentosAnterioresQuery } from 'graphql/types.generated'
import { AtendimentoIndividualRetificacaoModel } from 'view/retificacao-atendimento/util-retificacaoAtendimento'

export interface MedicaoPeso {
  dataMedicao: Instant
  peso: string
}

export enum OrigemDum {
  SEM_DUM,
  ATENDIMENTO_ANTERIOR,
  ATENDIMENTO_ATUAL,
  AMBOS,
}

export const STATUS_DUM_ATENDIMENTO_ATUAL: Set<OrigemDum> = new Set([OrigemDum.ATENDIMENTO_ATUAL, OrigemDum.AMBOS])

export const STATUS_DUM_ATENDIMENTO_ANTERIOR: Set<OrigemDum> = new Set([
  OrigemDum.ATENDIMENTO_ANTERIOR,
  OrigemDum.AMBOS,
])

export type MedicoesAtendimentoAnterior = MedicoesAtendimentosAnterioresQuery['medicoes'][0]
export type MedicoesNascimento = MedicoesAtendimentosAnterioresQuery['medicoesNoNascimento']
export type MedicoesRetificacaoQueryModel = AtendimentoIndividualRetificacaoModel['medicoes'][0]
