import { addWeeks, isBefore, toDate } from 'date-fns'
import { maxBy } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'

import { MedicaoAntropometricaPreNatal } from './model-medicoesPreNatal'
import { renderMedicaoPreNatal } from './render-medicoesPreNatal'

export function getPesoAndAlturaPreGestacional(
  dum: Date,
  medicoes: MedicaoAntropometricaPreNatal[]
): MedicaoAntropometricaPreNatal {
  if (isUndefinedOrNull(dum) || isUndefinedOrNull(medicoes) || medicoes.isEmpty()) return null

  const medicoesAnterioresDum = medicoes.filter(
    (medicao) =>
      isBefore(new Date(medicao.dataMedicao), dum) &&
      !isUndefinedOrNull(medicao.altura) &&
      !isUndefinedOrNull(medicao.peso)
  )

  const medicaoMaisRecenteAnteriorDum = maxBy(medicoesAnterioresDum, (medicao) => medicao.dataMedicao)

  if (isUndefinedOrNull(medicaoMaisRecenteAnteriorDum)) return null

  return medicaoMaisRecenteAnteriorDum
}

export function getMedicoesPeso10SemanasAposDum(
  dum: Date,
  medicoesPeso: MedicaoAntropometricaPreNatal[]
): MedicaoAntropometricaPreNatal[] {
  if (isUndefinedOrNull(dum) || isUndefinedOrNull(medicoesPeso) || medicoesPeso.isEmpty()) return null

  const dezSemanasAposDum = addWeeks(dum, 10)

  const medicoesPeso10SemanasAposDum = medicoesPeso.filter(
    (medicao) => !isUndefinedOrNull(medicao.peso) && !isBefore(toDate(medicao.dataMedicao), dezSemanasAposDum)
  )

  return medicoesPeso10SemanasAposDum
}

export function computeGanhoPesoGestacional(pesoPreGestacional: number, pesoConsulta: number): number {
  if (isUndefinedOrNull(pesoPreGestacional) || isUndefinedOrNull(pesoConsulta)) return null
  return pesoConsulta - pesoPreGestacional
}

export function renderGanhoPeso(pesoPreGestacional: number, pesoConsulta: number): String {
  var ganhoPeso = computeGanhoPesoGestacional(pesoPreGestacional, pesoConsulta)
  return `${ganhoPeso < -0.005 ? '-' : ''}${renderMedicaoPreNatal(Math.abs(ganhoPeso))}`
}

export function getPesoAtendimentoAtual(medicoesPeso: MedicaoAntropometricaPreNatal[]): number {
  return medicoesPeso.find((medicao) => medicao.tipoMedicao === 'atual')?.peso
}
