import { Button, DataTable, HFlow, Icon, TableFooter, Text, Tooltip } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { DateTime } from 'components/date/DateTime'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { ButtonLink } from 'components/route'
import { usePagedTableProps } from 'components/table'
import { TableBox } from 'components/table/TableBox'
import { css } from 'emotion'
import { useAtividadeColetivaTableLazyQuery, useExcluirAtividadeColetivaMutation } from 'graphql/hooks.generated'
import { AtividadeColetivaQueryInput } from 'graphql/types.generated'
import React, { MouseEvent, useCallback, useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import {
  AtividadeColetivaFilterFormModel,
  AtividadeColetivaTableItem,
  tipoAtividadeRecord,
} from 'view/atividade-coletiva/model-atividadeColetiva'

interface AtividadeColetivaTableProps {
  filter: AtividadeColetivaQueryInput
  onChangeFilter: (filter: AtividadeColetivaFilterFormModel) => void
}

export const AtividadeColetivaTable = (props: AtividadeColetivaTableProps) => {
  const { filter, onChangeFilter } = props
  const match = useRouteMatch()
  const handleRejection = useErrorHandler()
  const [excluirAtividadeColetiva] = useExcluirAtividadeColetivaMutation()
  const [executeQuery, { data, loading }] = useAtividadeColetivaTableLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  const executeQueryWithFilter = useCallback(() => executeQuery({ variables: { input: filter } }), [
    executeQuery,
    filter,
  ])

  useEffect(executeQueryWithFilter, [executeQueryWithFilter])

  const tableProps = usePagedTableProps({
    loading,
    result: data?.atividadesColetivas,
    onChange: onChangeFilter,
  })

  const renderDataAtividade = (item: AtividadeColetivaTableItem) => {
    return (
      <HFlow>
        <DateTime format='DD/MM/YYYY' value={item.dataAtividade} />
        {!item.registroEnviado && (
          <Tooltip text='Registro não processado para essa atividade'>
            <Icon icon='sync' size={1} />
          </Tooltip>
        )}
      </HFlow>
    )
  }

  const handleExcluir = (event: MouseEvent<HTMLButtonElement>, atividadeColetivaId: ID) => {
    event.stopPropagation()

    confirm({
      title: 'Deseja excluir o registro de Atividade coletiva?',
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        excluirAtividadeColetiva({ variables: { atividadeColetivaId } })
          .then(executeQueryWithFilter)
          .catch(handleRejection)
      },
    })()
  }

  const renderActions = (item: AtividadeColetivaTableItem) => (
    <HFlow hSpacing={0} justifyContent='flex-end'>
      <CheckPermission permission={Permissions.cds.fichaDeAtividadeColetiva.cadastrarEditarEExcluir}>
        <Tooltip text={item.registroEnviado ? 'Edição desabilitada para registro já processado' : 'Editar'}>
          <ButtonLink size='small' skin='ghost' to={`${match.url}/editar/${item.id}`} disabled={item.registroEnviado}>
            <Icon icon='penOutline' />
          </ButtonLink>
        </Tooltip>
      </CheckPermission>
      <CheckPermission permission={Permissions.cds.fichaDeAtividadeColetiva.cadastrarEditarEExcluir}>
        <Tooltip text={item.registroEnviado ? 'Exclusão desabilitada para registro já processado' : 'Excluir'}>
          <Button
            size='small'
            skin='ghost'
            onClick={(event: MouseEvent<HTMLButtonElement>) => handleExcluir(event, item.id)}
            disabled={item.registroEnviado}
          >
            <Icon icon='trashOutline' />
          </Button>
        </Tooltip>
      </CheckPermission>
      <Tooltip text='Visualizar'>
        <ButtonLink size='small' skin='ghost' to={`${match.url}/visualizar/${item.id}`}>
          <Icon icon='zoomOutline' />
        </ButtonLink>
      </Tooltip>
    </HFlow>
  )

  /**
   * TODO (Foundation): Ajustar acesso aos campos quando resolver for evoluído em #21428
   */
  return (
    <TableBox>
      <DataTable<AtividadeColetivaTableItem>
        columns={[
          {
            name: 'dataAtividade',
            header: 'Data da atividade',
            render: renderDataAtividade,
          },
          {
            name: 'tipoAtividade',
            header: 'Tipo de atividade',
            render: (item) => <Text>{tipoAtividadeRecord[item.tipoAtividade]}</Text>,
          },
          {
            name: 'numeroParticipantes',
            header: 'Nº de participantes',
            render: (item) => <Text>{item.numeroParticipantes}</Text>,
          },
          {
            name: 'responsavel',
            header: 'Responsável',
            render: (item) => <Text>{item.lotacaoResponsavel.profissional.nome}</Text>,
          },
          {
            name: 'actions',
            align: 'right',
            render: renderActions,
          },
        ]}
        {...tableProps}
      />
      <TableFooter style={styles.tableFooter} pageSize={filter.pageParams?.size} {...tableProps} />
    </TableBox>
  )
}

const styles = {
  tableFooter: css`
    border-top: 0;
  `,
}
