import { getSingleAgeRangeValue } from 'components/date/AgeRange'
import { FaixaEtariaEnum } from 'graphql/types.generated'
import { createValidator, ErrorObject, minRange, range, required } from 'util/validation'

import { AcompanhamentoCondicaoSaudeFormModel } from './model-acompanhamentoCondicaoSaude'

export const validate = createValidator<AcompanhamentoCondicaoSaudeFormModel>(
  {
    equipeResponsavel: required,
  },
  (values: AcompanhamentoCondicaoSaudeFormModel, errors: ErrorObject<AcompanhamentoCondicaoSaudeFormModel>) => {
    const { faixaEtariaRange, tipoFaixaEtaria, isBotaoIvcfAtivo, ivcfPontuacao } = values

    if (isBotaoIvcfAtivo) {
      errors.ivcfPontuacao = range(0, 40)(ivcfPontuacao?.firstValue) || range(0, 40)(ivcfPontuacao?.secondValue)
      errors.faixaEtariaRange =
        minRange(60)(faixaEtariaRange?.firstValue) && 'Idade mínima deve ter valor igual ou acima de 60.'
    }

    if (FaixaEtariaEnum.OUTRA === tipoFaixaEtaria && !errors.faixaEtariaRange) {
      errors.faixaEtariaRange = required(getSingleAgeRangeValue(faixaEtariaRange))
    }

    return errors
  }
)
