import { ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import {
  isProcedimentoSisab,
  shouldUseProcedimentoReferencia,
} from 'components/form/field/select/ProcedimentoSelectField/ProcedimentoSelectFieldItem'
import {
  ExameEspecificoEnum,
  FichaComplementarZikaMicrocefaliaResultadoExameEnum,
  ResultadoExameInput,
} from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { formatNumberRemovingDots } from 'util/number'
import { range, required, validate } from 'util/validation'

import { ConverterResultadoExameType, convertResultadoExameToInput } from '../converter-objetivo'
import { ResultadoExameMarcadoresBioquimicosDecimalField } from './adicionar/exames-especificos/ResultadoExameMarcadoresBioquimicosDecimalField'
import { ResultadoExameMarcadoresBioquimicosNumberField } from './adicionar/exames-especificos/ResultadoExameMarcadoresBioquimicosNumberField'
import { ResultadoExamePrenatalField } from './adicionar/exames-especificos/ResultadoExamePrenatalField'
import { ResultadoExamePuericulturaField } from './adicionar/exames-especificos/ResultadoExamePuericulturaField'
import {
  validateResultadoExamePrenatal,
  validateResultadoExamePuericultura,
} from './adicionar/validator-ResultadosExamesObjetivo'
import {
  AllResultadoEspecificoValueType,
  CodigosExamesEspecificos,
  ExameQueryModel,
  FactoryResultadoExameEspecificoFieldProps,
  FactoryResultadoExameEspecificoModel,
  FactoryResultadoExameEspecificoProperties,
  FactoryResultadoExameEspecificoViewProps,
  ResultadoExameEspecificoModel,
  ResultadosExamesModel,
} from './model-resultadosExames'
import {
  convertResultadoExameMarcadoresBioquimicosToInput,
  convertResultadoExameMarcadoresBioquimicosValueToModel,
  convertResultadoExamePrenatalToInput,
  convertResultadoExamePrenatalValueToModel,
  convertResultadoExamePuericulturaToInput,
  convertResultadoExamePuericulturaValueToModel,
} from './visualizar/exames-especificos/converter-ExamesEspecificos'
import {
  extractResultadoExameMarcadoresBioquimicosValue,
  extractResultadoExamePrenatalValue,
  extractResultadoExamePuericulturaValue,
} from './visualizar/exames-especificos/extract'
import { ResultadoExameMarcadoresBioquimicosView } from './visualizar/exames-especificos/ResultadoExameMarcadoresBioquimicosView'
import { ResultadoExamePrenatalView } from './visualizar/exames-especificos/ResultadoExamePrenatalView'
import { ResultadoExamePuericulturaView } from './visualizar/exames-especificos/ResultadoExamePuericulturaView'

const RESULTADOS_EXAMES_PREFIX = 'id'

const formatResultadoExameEspecificoDecimal = (
  value: ResultadoExameEspecificoModel,
  exame: ExameQueryModel
): ResultadoExameEspecificoModel => {
  const { resultado, ...prevValue } = value
  const { precision, maxLength } = getResultadoExameEspecificoProperties(exame)

  const tmpValue = formatNumberRemovingDots(resultado, precision).slice(0, maxLength)
  const lastChar = tmpValue.slice(-1)
  const newValue = lastChar === ',' ? tmpValue.slice(0, -1) : tmpValue

  return {
    ...prevValue,
    resultado: newValue as AllResultadoEspecificoValueType,
  }
}

export const resultadosExamesEspecificosFactory: Record<
  CodigosExamesEspecificos,
  FactoryResultadoExameEspecificoModel
> = {
  '0202010503': {
    validator: ({ value }) => validate(value, [required, range(0, 100)]),
    converterToInput: convertResultadoExameMarcadoresBioquimicosToInput,
    extractValueFromQuery: extractResultadoExameMarcadoresBioquimicosValue,
    converterValueToModel: convertResultadoExameMarcadoresBioquimicosValueToModel,
    formatOnSave: formatResultadoExameEspecificoDecimal,
    components: {
      ResultadoField: ResultadoExameMarcadoresBioquimicosDecimalField,
      ResultadoView: ResultadoExameMarcadoresBioquimicosView,
    },
    properties: {
      maxLength: 6,
      precision: 2,
      unit: '%',
      relatedDbEnum: ExameEspecificoEnum.HEMOGLOBINA_GLICADA,
    },
  },
  '0202010295': {
    validator: ({ value }) => validate(value, [required, range(1, 10000)]),
    converterToInput: convertResultadoExameMarcadoresBioquimicosToInput,
    converterValueToModel: convertResultadoExameMarcadoresBioquimicosValueToModel,
    extractValueFromQuery: extractResultadoExameMarcadoresBioquimicosValue,
    components: {
      ResultadoField: ResultadoExameMarcadoresBioquimicosNumberField,
      ResultadoView: ResultadoExameMarcadoresBioquimicosView,
    },
    properties: {
      maxLength: 5,
      unit: 'mg/dL',
      relatedDbEnum: ExameEspecificoEnum.COLESTEROL_TOTAL,
    },
  },
  '0202010279': {
    validator: ({ value }) => validate(value, [required, range(1, 10000)]),
    converterToInput: convertResultadoExameMarcadoresBioquimicosToInput,
    converterValueToModel: convertResultadoExameMarcadoresBioquimicosValueToModel,
    extractValueFromQuery: extractResultadoExameMarcadoresBioquimicosValue,
    components: {
      ResultadoField: ResultadoExameMarcadoresBioquimicosNumberField,
      ResultadoView: ResultadoExameMarcadoresBioquimicosView,
    },
    properties: {
      maxLength: 5,
      unit: 'mg/dL',
      relatedDbEnum: ExameEspecificoEnum.COLESTEROL_HDL,
    },
  },
  '0202010287': {
    validator: ({ value }) => validate(value, [required, range(1, 10000)]),
    converterToInput: convertResultadoExameMarcadoresBioquimicosToInput,
    converterValueToModel: convertResultadoExameMarcadoresBioquimicosValueToModel,
    extractValueFromQuery: extractResultadoExameMarcadoresBioquimicosValue,
    components: {
      ResultadoField: ResultadoExameMarcadoresBioquimicosNumberField,
      ResultadoView: ResultadoExameMarcadoresBioquimicosView,
    },
    properties: {
      maxLength: 5,
      unit: 'mg/dL',
      relatedDbEnum: ExameEspecificoEnum.COLESTEROL_LDL,
    },
  },
  '0202010678': {
    validator: ({ value }) => validate(value, [required, range(1, 10000)]),
    converterToInput: convertResultadoExameMarcadoresBioquimicosToInput,
    converterValueToModel: convertResultadoExameMarcadoresBioquimicosValueToModel,
    extractValueFromQuery: extractResultadoExameMarcadoresBioquimicosValue,
    components: {
      ResultadoField: ResultadoExameMarcadoresBioquimicosNumberField,
      ResultadoView: ResultadoExameMarcadoresBioquimicosView,
    },
    properties: {
      maxLength: 5,
      unit: 'mg/dL',
      relatedDbEnum: ExameEspecificoEnum.TRIGLICERIDIOS,
    },
  },
  '0202010317': {
    validator: ({ value }) => validate(value, [required, range(0.1, 500)]),
    converterToInput: convertResultadoExameMarcadoresBioquimicosToInput,
    converterValueToModel: convertResultadoExameMarcadoresBioquimicosValueToModel,
    extractValueFromQuery: extractResultadoExameMarcadoresBioquimicosValue,
    formatOnSave: formatResultadoExameEspecificoDecimal,
    components: {
      ResultadoField: ResultadoExameMarcadoresBioquimicosDecimalField,
      ResultadoView: ResultadoExameMarcadoresBioquimicosView,
    },
    properties: {
      maxLength: 5,
      precision: 1,
      unit: 'mg/dL',
      relatedDbEnum: ExameEspecificoEnum.DOSAGEM_DE_CREATINA,
    },
  },
  '0202050025': {
    validator: ({ value }) => validate(value, [required, range(0.001, 1000)]),
    converterToInput: convertResultadoExameMarcadoresBioquimicosToInput,
    converterValueToModel: convertResultadoExameMarcadoresBioquimicosValueToModel,
    extractValueFromQuery: extractResultadoExameMarcadoresBioquimicosValue,
    formatOnSave: formatResultadoExameEspecificoDecimal,
    components: {
      ResultadoField: ResultadoExameMarcadoresBioquimicosDecimalField,
      ResultadoView: ResultadoExameMarcadoresBioquimicosView,
    },
    properties: {
      maxLength: 8,
      precision: 3,
      unit: 'mL/min/1,73 m²',
      relatedDbEnum: ExameEspecificoEnum.CLEARANCE_DE_CREATINA,
    },
  },
  '0205020143': {
    validator: validateResultadoExamePrenatal,
    converterToInput: convertResultadoExamePrenatalToInput,
    converterValueToModel: convertResultadoExamePrenatalValueToModel,
    extractValueFromQuery: extractResultadoExamePrenatalValue,
    components: {
      ResultadoField: ResultadoExamePrenatalField,
      ResultadoView: ResultadoExamePrenatalView,
    },
    properties: {
      relatedDbEnum: ExameEspecificoEnum.PRENATAL,
    },
  },
  '0205020151': {
    validator: validateResultadoExamePrenatal,
    converterToInput: convertResultadoExamePrenatalToInput,
    converterValueToModel: convertResultadoExamePrenatalValueToModel,
    extractValueFromQuery: extractResultadoExamePrenatalValue,
    components: {
      ResultadoField: ResultadoExamePrenatalField,
      ResultadoView: ResultadoExamePrenatalView,
    },
    properties: {
      relatedDbEnum: ExameEspecificoEnum.PRENATAL,
    },
  },
  '0205010059': {
    validator: validateResultadoExamePrenatal,
    converterToInput: convertResultadoExamePrenatalToInput,
    converterValueToModel: convertResultadoExamePrenatalValueToModel,
    extractValueFromQuery: extractResultadoExamePrenatalValue,
    components: {
      ResultadoField: ResultadoExamePrenatalField,
      ResultadoView: ResultadoExamePrenatalView,
    },
    properties: {
      relatedDbEnum: ExameEspecificoEnum.PRENATAL,
    },
  },
  '0211070149': {
    validator: validateResultadoExamePuericultura,
    converterToInput: convertResultadoExamePuericulturaToInput,
    converterValueToModel: convertResultadoExamePuericulturaValueToModel,
    extractValueFromQuery: extractResultadoExamePuericulturaValue,
    components: {
      ResultadoField: ResultadoExamePuericulturaField,
      ResultadoView: ResultadoExamePuericulturaView,
    },
    properties: {
      options: [
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.ORELHINHA_FALHOU,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.ORELHINHA_PASSOU,
      ],
      relatedDbEnum: ExameEspecificoEnum.PEATEAB,
    },
  },
  '0211070270': {
    validator: validateResultadoExamePuericultura,
    converterToInput: convertResultadoExamePuericulturaToInput,
    converterValueToModel: convertResultadoExamePuericulturaValueToModel,
    extractValueFromQuery: extractResultadoExamePuericulturaValue,
    components: {
      ResultadoField: ResultadoExamePuericulturaField,
      ResultadoView: ResultadoExamePuericulturaView,
    },
    properties: {
      options: [
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.ORELHINHA_FALHOU,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.ORELHINHA_PASSOU,
      ],
      relatedDbEnum: ExameEspecificoEnum.PEATEST,
    },
  },
  '0205020178': {
    validator: validateResultadoExamePuericultura,
    converterToInput: convertResultadoExamePuericulturaToInput,
    converterValueToModel: convertResultadoExamePuericulturaValueToModel,
    extractValueFromQuery: extractResultadoExamePuericulturaValue,
    components: {
      ResultadoField: ResultadoExamePuericulturaField,
      ResultadoView: ResultadoExamePuericulturaView,
    },
    properties: {
      options: [
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.TRANSFONTANELA_NORMAL,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.TRANSFONTANELA_SUGESTIVO_INFECCAO_CONGENITA,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.TRANSFONTANELA_OUTRAS_ALTERACOES,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.TRANSFONTANELA_INDETERMINADO,
      ],
      relatedDbEnum: ExameEspecificoEnum.EXAMES_IMAGEM_US,
    },
  },
  '0206010079': {
    validator: validateResultadoExamePuericultura,
    converterToInput: convertResultadoExamePuericulturaToInput,
    converterValueToModel: convertResultadoExamePuericulturaValueToModel,
    extractValueFromQuery: extractResultadoExamePuericulturaValue,
    components: {
      ResultadoField: ResultadoExamePuericulturaField,
      ResultadoView: ResultadoExamePuericulturaView,
    },
    properties: {
      options: [
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.TOMOGRAFIA_NORMAL,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.TOMOGRAFIA_SUGESTIVO_INFECCAO_CONGENITA,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.TOMOGRAFIA_OUTRAS_ALTERACOES,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.TOMOGRAFIA_INDETERMINADO,
      ],
      relatedDbEnum: ExameEspecificoEnum.EXAMES_IMAGEM_TC,
    },
  },
  '0207010064': {
    validator: validateResultadoExamePuericultura,
    converterToInput: convertResultadoExamePuericulturaToInput,
    converterValueToModel: convertResultadoExamePuericulturaValueToModel,
    extractValueFromQuery: extractResultadoExamePuericulturaValue,
    components: {
      ResultadoField: ResultadoExamePuericulturaField,
      ResultadoView: ResultadoExamePuericulturaView,
    },
    properties: {
      options: [
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.RESSONANCIA_NORMAL,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.RESSONANCIA_SUGESTIVO_INFECCAO_CONGENITA,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.RESSONANCIA_OUTRAS_ALTERACOES,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.RESSONANCIA_INDETERMINADO,
      ],
      relatedDbEnum: ExameEspecificoEnum.EXAMES_IMAGEM_RMN,
    },
  },
  '0211060100': {
    validator: validateResultadoExamePuericultura,
    converterToInput: convertResultadoExamePuericulturaToInput,
    converterValueToModel: convertResultadoExamePuericulturaValueToModel,
    extractValueFromQuery: extractResultadoExamePuericulturaValue,
    components: {
      ResultadoField: ResultadoExamePuericulturaField,
      ResultadoView: ResultadoExamePuericulturaView,
    },
    properties: {
      options: [
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.FUNDO_OLHO_NORMAL,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.FUNDO_OLHO_ALTERADO,
      ],
      relatedDbEnum: ExameEspecificoEnum.FUNDOSCOPIA,
    },
  },
  ABEX022: {
    validator: validateResultadoExamePuericultura,
    converterToInput: convertResultadoExamePuericulturaToInput,
    converterValueToModel: convertResultadoExamePuericulturaValueToModel,
    extractValueFromQuery: extractResultadoExamePuericulturaValue,
    components: {
      ResultadoField: ResultadoExamePuericulturaField,
      ResultadoView: ResultadoExamePuericulturaView,
    },
    properties: {
      options: [
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.PRESENTE_BILATERAL,
        FichaComplementarZikaMicrocefaliaResultadoExameEnum.DUVIDOSO_OU_AUSENTE,
      ],
      relatedDbEnum: ExameEspecificoEnum.TESTE_OLHINHO,
    },
  },
}

const examesEspecificosKeys = Object.keys(resultadosExamesEspecificosFactory)

export const mapResultadosPuericulturaEnumLabel: Record<FichaComplementarZikaMicrocefaliaResultadoExameEnum, string> = {
  ORELHINHA_FALHOU: 'Falhou',
  ORELHINHA_PASSOU: 'Passou',
  TRANSFONTANELA_NORMAL: 'Normal',
  TRANSFONTANELA_SUGESTIVO_INFECCAO_CONGENITA: 'Sugestivo de infecção congênita',
  TRANSFONTANELA_OUTRAS_ALTERACOES: 'Outras alterações',
  TRANSFONTANELA_INDETERMINADO: 'Indeterminado',
  TOMOGRAFIA_NORMAL: 'Normal',
  TOMOGRAFIA_SUGESTIVO_INFECCAO_CONGENITA: 'Sugestivo de infecção congênita',
  TOMOGRAFIA_OUTRAS_ALTERACOES: 'Outras alterações',
  TOMOGRAFIA_INDETERMINADO: 'Indeterminado',
  RESSONANCIA_NORMAL: 'Normal',
  RESSONANCIA_SUGESTIVO_INFECCAO_CONGENITA: 'Sugestivo de infecção congênita',
  RESSONANCIA_OUTRAS_ALTERACOES: 'Outras alterações',
  RESSONANCIA_INDETERMINADO: 'Indeterminado',
  FUNDO_OLHO_NORMAL: 'Normal',
  FUNDO_OLHO_ALTERADO: 'Alterado',
  DUVIDOSO_OU_AUSENTE: 'Duvidoso ou ausente',
  PRESENTE_BILATERAL: 'Presente bilateral',
}

export const isExameEspecifico = (exame: ExameQueryModel): boolean => {
  const codigo = shouldUseProcedimentoReferencia(exame?.codigo) ? exame?.procedimentoReferencia?.codigo : exame?.codigo
  return examesEspecificosKeys.includes(codigo)
}

export const showCodigoProcedimento = (codigo: string): string => {
  return isProcedimentoSisab(codigo) ? 'CDS' : codigo
}

export const getResultadoExameEspecificoProperties = (
  exame: ExameQueryModel
): FactoryResultadoExameEspecificoProperties => {
  const codigo = shouldUseProcedimentoReferencia(exame?.codigo) ? exame?.procedimentoReferencia?.codigo : exame?.codigo
  return resultadosExamesEspecificosFactory?.[codigo]?.properties || {}
}

export const getResultadoExameEspecificoField = (
  exame: ExameQueryModel
): React.ComponentType<FactoryResultadoExameEspecificoFieldProps> => {
  const codigo = shouldUseProcedimentoReferencia(exame?.codigo) ? exame?.procedimentoReferencia?.codigo : exame?.codigo
  return resultadosExamesEspecificosFactory?.[codigo]?.components?.ResultadoField
}

export const getResultadoExameEspecificoView = (
  exame: ExameQueryModel
): React.ComponentType<FactoryResultadoExameEspecificoViewProps> => {
  const codigo = shouldUseProcedimentoReferencia(exame?.codigo) ? exame?.procedimentoReferencia?.codigo : exame?.codigo
  return resultadosExamesEspecificosFactory?.[codigo]?.components?.ResultadoView
}

export const getResultadoExameEspecificoValidator = (
  exame: ExameQueryModel
): FactoryResultadoExameEspecificoModel['validator'] => {
  const codigo = shouldUseProcedimentoReferencia(exame?.codigo) ? exame?.procedimentoReferencia?.codigo : exame?.codigo
  return resultadosExamesEspecificosFactory?.[codigo]?.validator
}

export const getResultadoExameEspecificoConverterToInput = (
  exame: ExameQueryModel
): FactoryResultadoExameEspecificoModel['converterToInput'] => {
  const codigo = shouldUseProcedimentoReferencia(exame?.codigo) ? exame?.procedimentoReferencia?.codigo : exame?.codigo
  return resultadosExamesEspecificosFactory?.[codigo]?.converterToInput
}

export const getResultadoExameEspecificoConverterToModel = (
  exame: ExameQueryModel
): FactoryResultadoExameEspecificoModel['converterValueToModel'] => {
  const codigo = shouldUseProcedimentoReferencia(exame?.codigo) ? exame?.procedimentoReferencia?.codigo : exame?.codigo
  return resultadosExamesEspecificosFactory?.[codigo]?.converterValueToModel
}

export const getResultadoExameEspecificoFormatOnSave = (
  exame: ExameQueryModel
): FactoryResultadoExameEspecificoModel['formatOnSave'] => {
  const codigo = shouldUseProcedimentoReferencia(exame?.codigo) ? exame?.procedimentoReferencia?.codigo : exame?.codigo
  return resultadosExamesEspecificosFactory?.[codigo]?.formatOnSave
}

export const getResultadoExameEspecificoExtractValueFromQuery = (
  exame: ExameQueryModel
): FactoryResultadoExameEspecificoModel['extractValueFromQuery'] => {
  const codigo = shouldUseProcedimentoReferencia(exame?.codigo) ? exame?.procedimentoReferencia?.codigo : exame?.codigo
  return resultadosExamesEspecificosFactory?.[codigo]?.extractValueFromQuery
}

export const getResultadoExameEspecificoPuericulturaLabel = (
  value: FichaComplementarZikaMicrocefaliaResultadoExameEnum
): string => {
  return mapResultadosPuericulturaEnumLabel[value]
}

export const addPrefix = (value: ID): string => RESULTADOS_EXAMES_PREFIX + value
export const removePrefix = (value: string): ID => value.replace(RESULTADOS_EXAMES_PREFIX, '')

export const isResultadoExamePreNatal = (resultadoExame: ResultadoExameEspecificoModel): boolean =>
  !isUndefinedOrNull(resultadoExame?.resultado?.dpp) || !isUndefinedOrNull(resultadoExame?.resultado?.idadeGestacional)

export const filterResultadosExamesAndConvertToInput = (
  resultadosExames: ResultadosExamesModel,
  filter: (resultado: ResultadoExameEspecificoModel) => boolean
): ResultadoExameInput[] => {
  const { resultadosComSolicitacao, resultadosSemSolicitacao } = resultadosExames

  const resultadosComSolicitacaoFiltradosInput = resultadosComSolicitacao
    ? Object.entries(resultadosComSolicitacao)
        .filter(([_, value]) => filter(value))
        .map(([id, value]) => convertResultadoExameToInput(value as ConverterResultadoExameType, id))
    : []

  const resultadosSemSolicitacaoFiltradosInput = resultadosSemSolicitacao
    ? Object.values(resultadosSemSolicitacao)
        .filter(filter)
        .map((value) => convertResultadoExameToInput(value))
    : []

  return [...resultadosComSolicitacaoFiltradosInput, ...resultadosSemSolicitacaoFiltradosInput]
}

export const PROCEDIMENTOS_ABEX_EXCEPTION_CODES: Set<string> = new Set(['ABEX012', 'ABEX013', 'ABEX022'])

export const showCodigoProcedimentoRef = (exame: ProcedimentoSelectModel, ocultaCodigo: boolean = false): string => {
  if (PROCEDIMENTOS_ABEX_EXCEPTION_CODES.has(exame.codigo)) return `${exame.descricao}${ocultaCodigo ? '' : ' - CDS'}`

  const shouldUseProcedimentoRef = shouldUseProcedimentoReferencia(exame.codigo)
  const codigo = shouldUseProcedimentoRef ? exame.procedimentoReferencia?.codigo : exame.codigo
  const nome = shouldUseProcedimentoRef ? exame.procedimentoReferencia?.descricao : exame.descricao

  return `${nome}${ocultaCodigo ? '' : ` - ${codigo}`}`
}
