import { ExposedDropdown } from 'components/dropdown/ExposedDropdown'
import React from 'react'

import { AtividadeColetivaFilterFormModel } from '../model-atividadeColetiva'

interface AtividadeColetivaSortDropdownProps {
  filter: AtividadeColetivaFilterFormModel
  onChangeFilter(filter: AtividadeColetivaFilterFormModel): void
}

export function AtividadeColetivaSortDropdown(props: AtividadeColetivaSortDropdownProps) {
  const { filter, onChangeFilter } = props

  const onChangeSelect = (sort: string) => {
    onChangeFilter({
      ...filter,
      pageParams: {
        ...filter.pageParams,
        sort: [sort],
      },
    })
  }

  return (
    <ExposedDropdown
      label='Ordenar por:'
      selectedItem={filter.pageParams?.sort?.[0]}
      items={items}
      itemToString={itemToString}
      onChange={onChangeSelect}
    />
  )
}

const itemToString = (sort: string) =>
  sort === 'dataAtividade' ? 'Data de atividade crescente' : 'Data de atividade decrescente'

const items = ['dataAtividade', '-dataAtividade']
