import { Alert } from 'bold-ui'
import { Chart, ChartBody, ChartContainer, ChartFooter } from 'bold-ui/lib/components/Chart'
import { gray } from 'bold-ui/lib/styles/colors'
import { differenceInDays } from 'date-fns'
import { groupBy, round } from 'lodash'
import React, { Fragment } from 'react'
import { calcularIMC } from 'util/atendimento'
import { isUndefinedOrNull } from 'util/checks'
import { dateAsDdMmYyyy, dateAsYyyyMmDd, toDate } from 'util/date/formatDate'

import { MedicaoAntropometricaPreNatal } from '../../../pre-natal/model-medicoesPreNatal'
import { computeGanhoPesoGestacional } from '../../../pre-natal/util-medicoesPreNatal'
import { reduceMedicoesIguais } from '../../../util-medicoes'
import { LinhaTooltipGrafico } from '../../components/LinhaTooltipGrafico'
import {
  categoriaImcPreGestacionalRecord,
  GANHO_PESO_EIXO_X,
  GANHO_PESO_EIXO_Y,
  GANHO_PESO_RANGE_AREAS,
} from './model-graficoGanhoPeso'
import { TagIMCPreGestacional } from './TagIMCPreGestacional'
import { TickSemanaGestacional } from './TickSemanaGestacional'
import { getCategoriaImcPreGestacional } from './util-graficoGanhoPeso'

interface GraficoGanhoPesoViewProps {
  medicaoPreGestacional: MedicaoAntropometricaPreNatal
  medicoesPeso10SemanasAposDum: MedicaoAntropometricaPreNatal[]
  dataInicioGestacao: Date
}

function generateSeriesData(
  medicoesUnicasPorDiaPesoGestacional: MedicaoAntropometricaPreNatal[],
  dataInicioGestacao: Date,
  medicaoPreGestacional: { peso: number }
) {
  return medicoesUnicasPorDiaPesoGestacional.map((medicao) => {
    const dataMedicaoAsDate = toDate(medicao.dataMedicao)
    const diffInDays = differenceInDays(dataMedicaoAsDate, dataInicioGestacao)
    const ganhoPesoGestacional = computeGanhoPesoGestacional(medicaoPreGestacional.peso, medicao.peso)
    return {
      x: round(diffInDays / 7, 2),
      y: round(ganhoPesoGestacional, 2),
      dataMedicao: dataMedicaoAsDate,
    }
  })
}

export function GraficoGanhoPesoView(props: GraficoGanhoPesoViewProps) {
  const { medicaoPreGestacional, medicoesPeso10SemanasAposDum, dataInicioGestacao } = props

  const hasPesoAlturaPreGestacional =
    !isUndefinedOrNull(medicaoPreGestacional?.peso) && !isUndefinedOrNull(medicaoPreGestacional?.altura)

  if (!hasPesoAlturaPreGestacional)
    return (
      <Alert type='info'>
        É preciso ter peso e altura pré-gestacional registrado para visualizar este gráfico. A data do registro deve ser
        anterior ao início da gestação. O registro pode ser feito na aba de Antropometria.
      </Alert>
    )

  const imcPreGestacional = calcularIMC(medicaoPreGestacional.peso, medicaoPreGestacional.altura)
  const categoriaImcPreGestacional = getCategoriaImcPreGestacional(imcPreGestacional)

  const medicoesUnicasPesoGestacional = medicoesPeso10SemanasAposDum
    ? reduceMedicoesIguais(medicoesPeso10SemanasAposDum, 'peso')
    : []

  const medicoesAgrupadasPorDia = groupBy(medicoesUnicasPesoGestacional, (medicao) =>
    dateAsYyyyMmDd(new Date(medicao.dataMedicao))
  )

  const medicoesUnicasPorDiaPesoGestacional = Object.values(medicoesAgrupadasPorDia).map(
    (medicoesPorDia) => medicoesPorDia[0]
  )

  const seriesData = generateSeriesData(medicoesUnicasPorDiaPesoGestacional, dataInicioGestacao, medicaoPreGestacional)

  return (
    <ChartContainer>
      <TagIMCPreGestacional categoriaImcPreGestacional={categoriaImcPreGestacional} />
      <ChartBody>
        <Chart<number>
          series={[{ name: 'Ganho de peso (kg)', color: gray.c20, data: seriesData }]}
          rangeAreas={GANHO_PESO_RANGE_AREAS}
          referenceAreas={categoriaImcPreGestacionalRecord[categoriaImcPreGestacional].referenceAreas}
          xAxis={{
            title: 'Idade gestacional (Semanas)',
            domain: GANHO_PESO_EIXO_X,
            tickRenderer: (props) => <TickSemanaGestacional {...props} />,
          }}
          yAxis={{
            title: '',
            unit: '',
            domain: GANHO_PESO_EIXO_Y,
          }}
          showLegend={false}
          tooltip={{
            type: 'point',
            render: (points) => (
              <>
                {points?.map((gridPoint, index) => {
                  const correspondingMedicao = seriesData.find(
                    (dataPoint) => dataPoint.x === gridPoint.x && dataPoint.y === gridPoint.y
                  )

                  return (
                    <Fragment key={index}>
                      <LinhaTooltipGrafico>{`Ganho de peso: ${gridPoint.y} kg`}</LinhaTooltipGrafico>
                      <LinhaTooltipGrafico>{`IG: ${Math.floor(gridPoint.x)} semanas`}</LinhaTooltipGrafico>
                      <LinhaTooltipGrafico>{`Data: ${dateAsDdMmYyyy(
                        correspondingMedicao?.dataMedicao
                      )}`}</LinhaTooltipGrafico>
                    </Fragment>
                  )
                })}
              </>
            ),
          }}
        />
      </ChartBody>
      <ChartFooter>Fonte: Caderneta da Gestante - 2022</ChartFooter>
    </ChartContainer>
  )
}
