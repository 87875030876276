import { isEqual } from 'lodash'
import { DependencyList, useCallback, useMemo, useRef } from 'react'

export const useMemoWithDeepCompare = <T>(factory: () => T, deps: DependencyList | undefined): T => {
  const ref = useRef<T>()

  const callback = useCallback(() => {
    const newValue = factory()

    if (!isEqual(newValue, ref.current)) {
      ref.current = newValue
    }

    return ref.current
  }, [factory])

  return useMemo(callback, deps)
}
