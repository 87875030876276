import { HFlow, Omit, Tag, Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import React from 'react'
import { IVCF_GRUPOS, IVCF_SUBGRUPOS, IvcfSubGrupoEnum } from 'view/atendimentos/detail/soap/objetivo/ivcf/model-ivcf'

interface IvcfSubgrupoSelectFieldProps extends Omit<SelectFieldProps<IvcfSubGrupoEnum>, 'items' | 'itemToString'> {}

export function IvcfSubgrupoSelectField(props: IvcfSubgrupoSelectFieldProps) {
  return (
    <SelectField<IvcfSubGrupoEnum>
      items={items}
      itemToString={renderString}
      renderItem={renderItem}
      multiple
      {...props}
    />
  )
}

const renderString = (item: IvcfSubGrupoEnum) => item && IVCF_SUBGRUPOS[item].label

const renderItem = (item: IvcfSubGrupoEnum) => {
  const subgrupo = IVCF_SUBGRUPOS[item]
  return (
    <HFlow hSpacing={1} alignItems='center'>
      <Tag>{IVCF_GRUPOS[subgrupo.grupo].title}</Tag>
      <Text>{subgrupo.label}</Text>
    </HFlow>
  )
}

const items: IvcfSubGrupoEnum[] = Object.values(IvcfSubGrupoEnum)
