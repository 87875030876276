/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DateFieldProps, ExternalStyles, FormControl, HFlow, Text } from 'bold-ui'
import { DateField, IdadeField, IdadeFieldModel } from 'components/form'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import theme from 'config/theme'
import { isAfter, isBefore } from 'date-fns'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import moment from 'moment'
import { useField, useFormState } from 'react-final-form'
import { CypressProps } from 'types/cypress'
import { calculateAge } from 'util/date/calculateAge'
import { toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'

export interface DataIdadeFieldModel {
  data?: LocalDate
  idade?: IdadeFieldModel
}

export interface DataIdadeFieldProps extends Pick<DateFieldProps, 'minDate' | 'maxDate'>, CypressProps {
  name: MetaPath<DataIdadeFieldModel>
  label?: string
  dataNascimentoCidadao: LocalDate
  style?: {
    hFlow?: ExternalStyles
    dateField?: ExternalStyles
    idadeField?: ExternalStyles
  }
  required?: boolean
}

export function DataIdadeField(props: DataIdadeFieldProps) {
  const { name, label, dataNascimentoCidadao, style, required, minDate, maxDate, dataTestId } = props

  const { values: formValues } = useFormState({ subscription: { values: true } })
  const values = resolveValue(formValues, name) || {}
  const { atendimentoProfissional } = useAtendimentoContext()

  const {
    input: { onChange: setData },
  } = useField(name.data.absolutePath())
  const {
    input: { onChange: setAnosIdade },
  } = useField(name.idade.anos.absolutePath())
  const {
    input: { onChange: setMesesIdade },
  } = useField(name.idade.meses.absolutePath())

  const handleDateBlur = () => {
    const { years, months } = calculateAge(dataNascimentoCidadao, values?.data)
    const ehDataInvalida =
      !values?.data ||
      isAfter(toDate(values?.data), atendimentoProfissional.iniciadoEm) ||
      isBefore(toDate(values?.data), toDate(dataNascimentoCidadao))
    setAnosIdade(!ehDataInvalida ? years : null)
    setMesesIdade(!ehDataInvalida ? months : null)
  }

  const handleIdadeBlur = () => {
    const { years, months } = calculateAge(dataNascimentoCidadao, values?.data)
    setData(
      (values?.idade?.meses || values?.idade?.anos) && (values.idade.meses !== months || values.idade.anos !== years)
        ? moment(dataNascimentoCidadao)
            .add(values.idade.meses, 'month')
            .add(values.idade.anos, 'year')
            .format('YYYY-MM-DD')
            .toString()
        : values?.data
    )
  }

  return (
    <FormControl label={label}>
      <HFlow
        style={css(
          css`
            border: 1px solid ${theme.pallete.gray.c80};
            padding: 0.5rem;
            width: 28.5rem;
          `,
          style?.hFlow
        )}
      >
        <DateField
          name={name.data}
          label='Data'
          clearable
          style={css(
            css`
              min-width: 11.8rem;
            `,
            style?.dateField
          )}
          onBlur={handleDateBlur}
          required={required}
          minDate={minDate}
          maxDate={maxDate}
          dataTestId={dataTestId}
        />
        <FormControl label={<span>&nbsp;</span>}>
          <Text
            fontWeight='bold'
            style={css`
              line-height: 2rem;
            `}
          >
            OU
          </Text>
        </FormControl>
        <IdadeField
          style={css(
            css`
              min-width: 12.5rem;
            `,
            style?.idadeField
          )}
          name={name.idade}
          label='Idade'
          onBlur={handleIdadeBlur}
          holderAnos='000'
        />
      </HFlow>
    </FormControl>
  )
}
