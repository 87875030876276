import { Alert, Cell, Grid } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import React, { Fragment, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath } from 'util/metaPath'
import {
  MENSAGEM_RESPONSAVEL_ACOMPANHAMENTO_CBO_SEM_PERMISSAO,
  MENSAGEM_RESPONSAVEL_ACOMPANHAMENTO_SEM_CBO,
  MENSAGEM_RESPONSAVEL_NAO_INFORMADO,
} from 'view/cadastro-imovel/model-cadastroImovel'

import { EditarLotacaoResponsavelModal } from './EditarLotacaoResponsavelModal'
import { LotacaoResponsavelCard } from './LotacaoResponsavelCard'
import { LotacaoResponsavelFormModel } from './model-lotacaoResponsavel'

interface LotacaoResponsavelSectionProps {
  name: MetaPath<LotacaoResponsavelFormModel>
  isCadastroImovel?: boolean
  isAtividadeColetiva?: boolean
  loadItemsOnOpen?: boolean
}

export function LotacaoResponsavelSection(props: LotacaoResponsavelSectionProps) {
  const { name, isCadastroImovel, isAtividadeColetiva, loadItemsOnOpen } = props
  const { acesso } = useAcessoLotacaoOrEstagio()
  const [isModalResponsavelOpen, setIsModalResponsavelOpen] = useState(false)

  const {
    input: {
      value: { lotacao },
      onChange,
    },
  } = usePecField({ name: name.absolutePath() })

  const handleEditarLotacaoResponsavel = (novoResponsavel: LotacaoAndEstagioSelectFieldModel) => {
    onChange({ lotacao: novoResponsavel })
  }

  const errorMessage = getLotacaoErrorMessage(lotacao)

  return (
    <Fragment>
      <Grid>
        <Cell size={12}>
          <LotacaoResponsavelCard responsavel={lotacao} onAlterarResponsavel={() => setIsModalResponsavelOpen(true)} />
        </Cell>
        {isCadastroImovel && errorMessage && (
          <>
            <Cell size={7}>
              <Alert type='danger'>{getLotacaoErrorMessage(lotacao)}</Alert>
            </Cell>
            <Cell size={5} />
          </>
        )}
      </Grid>

      <EditarLotacaoResponsavelModal
        open={isModalResponsavelOpen}
        municipioId={acesso.municipio.id}
        onClose={() => setIsModalResponsavelOpen(false)}
        onResponsavelChange={handleEditarLotacaoResponsavel}
        isCadastroImovel={isCadastroImovel}
        isAtividadeColetiva={isAtividadeColetiva}
        loadItemsOnOpen={loadItemsOnOpen}
      />
    </Fragment>
  )
}

const getLotacaoErrorMessage = (lotacao: LotacaoAndEstagioSelectFieldModel) => {
  if (isUndefinedOrNull(lotacao.profissional)) {
    return MENSAGEM_RESPONSAVEL_NAO_INFORMADO
  }

  if (isUndefinedOrNull(lotacao.cbo)) {
    return MENSAGEM_RESPONSAVEL_ACOMPANHAMENTO_SEM_CBO
  }

  if (!lotacao.cbo.actions?.cadastroDomiciliar.enabled || !lotacao.cbo.actions?.cadastroIndividual.enabled) {
    return MENSAGEM_RESPONSAVEL_ACOMPANHAMENTO_CBO_SEM_PERMISSAO
  }
}
