import { css } from '@emotion/core'
import { Alert, Button, ButtonGroup, Cell, Grid, Heading, HFlow, VFlow } from 'bold-ui'
import { green } from 'bold-ui/lib/styles/colors'
import { Box } from 'components/Box'
import { CheckboxField, FormRenderProps } from 'components/form'
import { AgeRangeField } from 'components/form/field/AgeRangeField'
import { AgeRangeType, RiscoVulnerabilidadeIvcfEnum } from 'graphql/types.generated'
import React, { useState } from 'react'
import { MetaRoot } from 'util/metaPath'
import { IvcfButton } from 'view/atendimentos/detail/soap/objetivo/ivcf/components/IvcfButton'
import { IVCF_RESULTADOS } from 'view/atendimentos/detail/soap/objetivo/ivcf/model-ivcf'
import { filterCheckboxLabelRender } from 'view/atendimentos/list/filter/ListaAtendimentoFilterPopperForm'

import { AcompanhamentoFilterModel } from '../models/model-acompanhamentoFilter'
import { IvcfSubgrupoSelectField } from './IvcfSubgrupoSelectField'

interface IvcfFilterGroup<T> {
  meta: MetaRoot<T>
  values: AcompanhamentoFilterModel
  onChange: FormRenderProps<AcompanhamentoFilterModel>['form']['change']
}

export function IvcfFilterGroup<T extends AcompanhamentoFilterModel>(props: IvcfFilterGroup<T>) {
  const { onChange, meta, values } = props

  const [option, setOption] = useState<boolean>(false)

  const handleClickIvcf = () => {
    if (values?.isBotaoIvcfAtivo) {
      onChange(meta.ivcfRiscoVulnerabilidade.absolutePath(), undefined)
      onChange(meta.ivcfPontuacao.absolutePath(), undefined)
      onChange(meta.ivcfSubgrupo.absolutePath(), undefined)
      onChange(meta.exibirCidadaoIdosoSemIvcf.absolutePath(), undefined)
      setOption(false)
    }
    return onChange(meta.isBotaoIvcfAtivo.absolutePath(), !values?.isBotaoIvcfAtivo)
  }

  const handleChangeButtonGroup = (itemGroup: boolean) => {
    return () => {
      setOption(itemGroup)
      itemGroup
        ? onChange(meta.ivcfRiscoVulnerabilidade.absolutePath(), undefined)
        : onChange(meta.ivcfPontuacao.absolutePath(), undefined)
    }
  }

  return (
    <VFlow vSpacing={0.5}>
      <IvcfButton isIvcfAtivo={values.isBotaoIvcfAtivo} onClick={handleClickIvcf} />
      {values.isBotaoIvcfAtivo && (
        <VFlow vSpacing={0.5}>
          <Box style={styles.box}>
            <VFlow vSpacing={0.5}>
              <Heading level={4}>Buscar resultados do IVCF-20 por:</Heading>
              <ButtonGroup>
                <Button
                  kind={!option ? 'primary' : 'normal'}
                  size='small'
                  skin='default'
                  onClick={handleChangeButtonGroup(false)}
                >
                  Índice
                </Button>
                <Button
                  kind={option ? 'primary' : 'normal'}
                  size='small'
                  skin='default'
                  onClick={handleChangeButtonGroup(true)}
                >
                  Pontuação
                </Button>
              </ButtonGroup>
              <Grid style={styles.center}>
                <Cell size={6}>
                  <Box>
                    {!option ? (
                      <HFlow>
                        <CheckboxField
                          name={meta.ivcfRiscoVulnerabilidade.absolutePath()}
                          label={filterCheckboxLabelRender(
                            RiscoVulnerabilidadeIvcfEnum.BAIXO.capitalize(),
                            styles.indiceBaixoIvcf
                          )}
                          value={RiscoVulnerabilidadeIvcfEnum.BAIXO}
                          style={styles.indiceCheckbox}
                        />
                        <CheckboxField
                          name={meta.ivcfRiscoVulnerabilidade.absolutePath()}
                          label={filterCheckboxLabelRender(
                            RiscoVulnerabilidadeIvcfEnum.MODERADO.capitalize(),
                            styles.indiceModeradoIvcf
                          )}
                          value={RiscoVulnerabilidadeIvcfEnum.MODERADO}
                          style={styles.indiceCheckbox}
                        />
                        <CheckboxField
                          name={meta.ivcfRiscoVulnerabilidade.absolutePath()}
                          label={filterCheckboxLabelRender(
                            RiscoVulnerabilidadeIvcfEnum.ALTO.capitalize(),
                            styles.indiceAltoIvcf
                          )}
                          value={RiscoVulnerabilidadeIvcfEnum.ALTO}
                        />
                      </HFlow>
                    ) : (
                      <AgeRangeField
                        name={meta.ivcfPontuacao.absolutePath()}
                        rangeTypeOptions={[AgeRangeType.ANOS]}
                        firstInputPlaceholder='Pontuação mín.'
                        secondInputPlaceholder='Pontuação máx.'
                        defaultOptionLabel='Pontos'
                      />
                    )}
                  </Box>
                </Cell>
                <Cell size={6}>
                  <CheckboxField
                    name={meta.exibirCidadaoIdosoSemIvcf.absolutePath()}
                    label='Exibir cidadãos 60+ sem registro do IVCF-20'
                  />
                </Cell>
                <Cell size={6}>
                  <IvcfSubgrupoSelectField name={meta.ivcfSubgrupo.absolutePath()} label='Dimensões alteradas' />
                </Cell>
                <Cell size={6} />
              </Grid>
            </VFlow>
          </Box>
          <Alert inline type='warning'>
            O IVCF-20 está disponível apenas para cidadãos com 60 anos ou mais.
          </Alert>
        </VFlow>
      )}
    </VFlow>
  )
}

const styles = {
  box: css`
    border-color: ${green.c40};
  `,
  center: css`
    align-items: center;
  `,
  indiceCheckbox: css`
    margin-right: 0.5rem;
  `,
  indiceBaixoIvcf: css`
    background: ${IVCF_RESULTADOS.BAIXO.textColor};
  `,
  indiceModeradoIvcf: css`
    background: ${IVCF_RESULTADOS.MODERADO.lineBgColor};
  `,
  indiceAltoIvcf: css`
    background: ${IVCF_RESULTADOS.ALTO.textColor};
  `,
}
